import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { Icon } from "semantic-ui-react";

const controlColorBtn = (color) => {
	switch (color) {
		case "success":
			return { background_color: "#4caf50", color: "#fff", active: "#2e7d32" };
		case "warning":
			return { background_color: "#ff9800", color: "#fff", active: "#ed6c02" };
		case "error":
			return { background_color: "#ef5350", color: "#fff", active: "#d32f2f" };
	}
};

export const BoxContent = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
	border-radius: 10px;
	height: 80vh;
`;

export const BoxSelectOption = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
	border-radius: 10px;
	border: 1px solid #f0f0f0;
`;

export const ImageContainer = styled.div`
	width: 60px;
	height: auto;
	opacity: 0.2;
	overflow: hidden;
`;

export const StyledImage = styled.img`
	width: 100%;
	height: auto;
	object-fit: cover;
`;

export const BoxListSelected = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	padding: 20px;
	border: 1px solid #f0f0f0;
	margin-top: 10px;
	border-radius: 10px;
	overflow: auto;
	/* ::-webkit-scrollbar {
		display: none;
	}
	scrollbar-width: none;
	-ms-overflow-style: none; */
`;

export const ButtonStyled = styled.button`
	padding: 5px 10px;
	border: none;
	margin: 0 5px;
	width: ${(props) => (props.width ? props.width : "90px")};
	border-radius: 5px;
	cursor: pointer;
	box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
	background-color: ${(props) => (props.color ? controlColorBtn(props.color).background_color : "#f0f0f0")};
	color: ${(props) => (props.color ? controlColorBtn(props.color).color : "#333")};
	:hover {
		box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
		background-color: ${(props) => (props.color ? controlColorBtn(props.color).active : "#f0f0f0")};
	}
`;

export const BoxSelectContent = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${({ active }) => (active ? "#fdedca" : "#fafafa")};
	padding: 10px;
	border-radius: 10px;
	width: 100%;
	text-overflow: ellipsis;
	cursor: pointer;
	color: ${({ active }) => (active ? "hsl(36, 100%, 27%)" : "inherit")};
	font-weight: ${({ active }) => (active ? "bold" : "normal")};
	box-shadow: ${({ active }) => (active ? "rgba(0, 0, 0, 0.04) 0px 3px 5px" : "none")};

	:hover {
		background-color: #fdedca;
		color: hsl(36, 100%, 27%);
		font-weight: bold;
		/* box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; */
	}
`;

export const Chip = styled.div`
	background-color: #fdedca;
	padding: 4px 10px;
	border-radius: 15px;
	display: inline-block;
	justify-content: space-between;
	align-items: center;
	font-weight: 500;
	color: hsl(36, 100%, 27%);
	height: fit-content;
	white-space: nowrap;
	border: 1px solid #ffda47;
`;

export const ChipContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	width: 100%;
`;

export const BoxSuggestionsContent = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #fafafa;
	padding: 10px;
	border-radius: 10px;
	width: 100%;
	text-overflow: ellipsis;
	font-weight: normal;
`;

const AutocompleteContainer = styled.div`
	position: relative;
	display: inline-block;
	width: 100%;
	margin-bottom: 10px;
`;

const Input = styled.input`
	border: 1px solid transparent;
	background-color: #f1f1f1;
	padding: 10px;
	font-size: 16px;
	width: 100%;
	&:hover {
		border: 1px solid #ccc;
	}
`;

const AutocompleteItems = styled.div`
	position: absolute;
	border: 1px solid #d4d4d4;
	border-bottom: none;
	border-top: none;
	z-index: 99;
	top: 100%;
	left: 0;
	right: 0;
`;

const AutocompleteItem = styled.div`
	padding: 10px;
	cursor: pointer;
	background-color: #fff;
	border-bottom: 1px solid #d4d4d4;

	&:hover {
		background-color: #e9e9e9;
	}

	&.autocomplete-active {
		background-color: DodgerBlue !important;
		color: #fff;
	}
`;

export const Autocomplete = ({ suggestions, onChange, values, onClickRemove, showBtnRemove }) => {
	const [inputValue, setInputValue] = useState("");
	const [filteredSuggestions, setFilteredSuggestions] = useState([]);
	const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [isSelecting, setIsSelecting] = useState(false);

	useEffect(() => {
		if (inputValue) {
			const filtered = suggestions.filter((suggestion) => suggestion.text.toLowerCase().startsWith(inputValue.toLowerCase()));
			setFilteredSuggestions(filtered);
			if (isSelecting) {
				setShowSuggestions(false);
			} else {
				setShowSuggestions(true);
			}
		} else {
			setShowSuggestions(false);
			setFilteredSuggestions([]);
		}
	}, [inputValue, suggestions, isSelecting]);

	const handleInputChange = (e) => {
		const value = e.target.value;
		setInputValue(value.toUpperCase());
		setIsSelecting(false);
		setActiveSuggestionIndex(-1);
		onChange(value.toUpperCase());
	};

	const handleClick = (suggestion) => {
		setInputValue(suggestion.text);
		setFilteredSuggestions([]);
		setShowSuggestions(false);
		setIsSelecting(true);
		onChange(suggestion.text);
	};

	const handleKeyDown = (e) => {
		if (e.keyCode === 13) {
			// Enter key
			e.preventDefault();
			if (activeSuggestionIndex >= 0 && activeSuggestionIndex < filteredSuggestions.length) {
				setInputValue(filteredSuggestions[activeSuggestionIndex].text);
				setFilteredSuggestions([]);
				setShowSuggestions(false);
				setIsSelecting(true);
				onChange(filteredSuggestions[activeSuggestionIndex].text);
			}
		} else if (e.keyCode === 38) {
			// Up arrow
			e.preventDefault();
			if (activeSuggestionIndex === 0) return;
			setActiveSuggestionIndex(activeSuggestionIndex - 1);
		} else if (e.keyCode === 40) {
			// Down arrow
			e.preventDefault();
			if (activeSuggestionIndex + 1 === filteredSuggestions.length) return;
			setActiveSuggestionIndex(activeSuggestionIndex + 1);
		}
	};

	return (
		<AutocompleteContainer>
			<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
				<Input
					type="text"
					placeholder="ชื่อทางการค้า"
					value={values}
					onChange={handleInputChange}
					onKeyDown={handleKeyDown}
				/>
				{showBtnRemove && (
					<div
						style={{
							padding: 9,
							background: "#e0e1e2",
							borderTopRightRadius: "5px",
							borderBottomRightRadius: "5px",
							marginLeft: "-5px",
							cursor: "pointer",
						}}
						onClick={onClickRemove}
					>
						<Icon
							name="close"
							style={{ margin: 0 }}
						/>
					</div>
				)}
			</div>

			{inputValue && showSuggestions && (
				<AutocompleteItems>
					{filteredSuggestions.length
						? filteredSuggestions.map((suggestion, index) => (
								<AutocompleteItem
									key={suggestion.key}
									onClick={() => handleClick(suggestion)}
									className={index === activeSuggestionIndex ? "autocomplete-active" : ""}
								>
									<strong>{suggestion.text.substr(0, inputValue.length)}</strong>
									{suggestion.text.substr(inputValue.length)}
								</AutocompleteItem>
						  ))
						: null}
				</AutocompleteItems>
			)}
		</AutocompleteContainer>
	);
};
