import React, { useState, useEffect } from "react";
import { Container, Grid, GridColumn, GridRow, Dimmer, Loader } from "semantic-ui-react";
import styled from "styled-components";
import BoxMultipleSelect from "../components/OIEControl/suggestion/BoxMultipleSelect";
import ListBandComponent from "../components/OIEControl/suggestion/ListBandComponent";
import { createListSuggestion, deleteListSuggestion, getListSuggestion, updateListSuggestion } from "../components/router";
import Swal from "sweetalert2";

const StyledContainer = styled.div`
	padding: 30px;
	display: flex;
	flex-direction: column;
`;

const Suggestion = () => {
	const [data, setData] = useState({});
	const [isLoading, setisLoading] = useState(false);

	useEffect(() => {
		fnFetchData();
	}, []);

	const fnFetchData = async () => {
		setisLoading(true);
		try {
			let response = await getListSuggestion();
			setData(response.value);
			setisLoading(false);
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "เกิดข้อผิดพลาด",
				text: "กรุณาลองอีกครั้ง",
				// footer: error.message,
			});
			setisLoading(false);
		}
	};

	const fnUpdateListSuggestion = async (val) => {
		setisLoading(true);
		try {
			await updateListSuggestion(val);
			await fnFetchData();
			setisLoading(false);
			Swal.fire({
				icon: "success",
				title: "อัพเดทสําเร็จ",
				text: "อัพเดทข้อมูลสําเร็จ",
				showConfirmButton: false,
				timer: 1500,
			});
		} catch (error) {
			const statusCode = error?.response?.status;
			const errorMessage = error?.response?.data?.message || error.message;
			Swal.fire({
				icon: "error",
				title: `เกิดข้อผิดพลาด${statusCode && "หรือข้อมูลซ้ำ"}`,
				text: "กรุณาลองอีกครั้ง",
				footer: errorMessage,
			});
			setisLoading(false);
		}
	};
	const fnDeleteListSuggestion = async (val) => {
		setisLoading(true);
		try {
			await deleteListSuggestion(val);
			await fnFetchData();
			setisLoading(false);
			Swal.fire({
				icon: "success",
				title: "ลบสําเร็จ",
				text: "ลบข้อมูลสําเร็จ",
				showConfirmButton: false,
				timer: 1500,
			});
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "เกิดข้อผิดพลาด",
				text: "กรุณาลองอีกครั้ง",
				// footer: error.message,
			});
			setisLoading(false);
		}
	};

	const fnCreateListSuggestion = async (val) => {
		setisLoading(true);
		try {
			await createListSuggestion(typeof val === "string" ? { name: [val] } : { name: [...val] });
			await fnFetchData();
			setisLoading(false);
			Swal.fire({
				icon: "success",
				title: "บันทึกข้อมูลสําเร็จ",
				text: "สร้างรายการแนะนำชื่อทางการค้าสำเร็จ",
				showConfirmButton: false,
				timer: 1500,
			});
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "เกิดข้อผิดพลาด",
				text: "กรุณาลองอีกครั้ง",
				footer: error.message,
			});
			setisLoading(false);
		}
	};

	return (
		<Container>
			<StyledContainer>
				<Grid>
					<GridRow divided>
						<GridColumn width={9}>
							<BoxMultipleSelect
								data={data.brands}
								handleSubmit={fnCreateListSuggestion}
							/>
						</GridColumn>
						<GridColumn width={7}>
							<ListBandComponent
								data={data.brands_suggestion}
								fnUpdateListSuggestion={fnUpdateListSuggestion}
								fnCreateListSuggestion={fnCreateListSuggestion}
								fnDeleteListSuggestion={fnDeleteListSuggestion}
							/>
						</GridColumn>
					</GridRow>
				</Grid>
			</StyledContainer>
			<Dimmer
				active={isLoading}
				inverted
			>
				<Loader size="large">Loading</Loader>
			</Dimmer>
		</Container>
	);
};

export default Suggestion;
