import React, { Component } from "react";
import axios from "axios";
import {
  Container,
  Segment,
  Header,
  Icon,
  Divider,
  Form,
  Dropdown,
  Grid,
  Message,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { connect } from "react-redux"; // connect redux
import Requestbutton from "./requestButton";
import Headers from "../header/requestHeader";
import {
  GET_DRAFT_DATA_BY_ID,
  GET_REQUEST_DATA_BY_COMPANY_ID,
  GET_REQUEST_DATA_BY_ID,
  GET_REQUEST_DATA_COMPANY,
  TOKEN_COMPANY,
} from "../router";
import Swal from "sweetalert2";
import { Redirect } from "react-router-dom";

const theme_form_color_1 = "violet";

const user_type_options = [
  {
    type: 3,
    key: 1,
    text: "ผู้ผลิต",
    value: "ผู้ผลิต"
  },
  {
    type: 4,
    key: 2,
    text: "ผู้นำเข้า",
    value: "ผู้นำเข้า"
  }
];

const mapStateToProps = function (state) {
  return {
    requestRedux: state.requestRedux
  };
};

class requestLicense extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      isLoadFormCheck: false,
      validForms: true,
      productRequest: [
        {
          id: "",
          productId: "",
          productTypeId: "",
          productTypeStructId: "",
          productName: "",
          licenseType: null,
          licenseNumber: "",
          licenseNumberFile: { name: "", data: [], path: "" },
          listProduct: "",
          requestNO: '',
          brandsRequest: [
            {
              id: "",
              brandId: "",
              brandName: "",
              genRequest: [
                {
                  id: "",
                  genName: "",
                  genLayoutFile: { name: "", data: [], path: "" },
                  sizeRequest: [
                    {
                      id: "",
                      sizeGen: "",
                      sizeCapacity: "",
                      sizeSpeedSymbol: "",
                      sizeSpecialFeature: "",
                      sizeStructure: "",
                      sizeTypeOfUse: "",
                      sizeCuttingWidth: "",
                      sizeSymbol: ""
                    }
                  ]
                }
              ]
            }
          ],
          testResult: {
            id: "",
            testName_a: "ค่าสัมประสิทธิ์ของความต้านทานการหมุน",
            testValue_a: "0",
            testUnit_a: "N/kN",
            brandName_a: "",
            genName_a: "",
            sizeGen_a: "",
            sizeSymbol_a: "",
            testPage_a: "",
            testFile_a: {
              name: "",
              data: [],
              path: ""
            },

            testName_b: "ค่าดัชนีของการยึดเกาะถนนบนพื้นเปียก",
            testValue_b: "0",
            testUnit_b: "G",
            brandName_b: "",
            genName_b: "",
            sizeGen_b: "",
            testPage_b: "",
            testFile_b: {
              name: "",
              data: [],
              path: ""
            },

            testName_c: "ค่าระดับเสียงจากยางล้อที่สัมผัสผิวถนน",
            testValue_c: "0",
            testUnit_c: "dB(A)",
            brandName_c: "",
            genName_c: "",
            sizeGen_c: "",
            testPage_c: "",
            testFile_c: {
              name: "",
              data: [],
              path: ""
            },

            testName_d: "ค่าดัชนีการเกาะถนนหิมะ",
            testValue_d: "0",
            testUnit_d: "SG",
            brandName_d: "",
            genName_d: "",
            sizeGen_d: "",
            testPage_d: "",
            testFile_d: {
              name: "",
              data: [],
              path: ""
            }
          }
        }
      ],
      factoryImportTypeOptions: [
        {
          key: null,
          text: "",
          value: "",
          factory_type_id: "",
          product_type_options: [
            {
              key: null,
              text: "",
              value: "",
              product_type_id: "",
              product_type_struct_id: "",
              brand_options: [
                {
                  key: null,
                  text: "",
                  value: "",
                  brand_id: ""
                }
              ]
            }
          ]
        }
      ],
      factoryManufacturerTypeOptions: [
        {
          key: null,
          text: "",
          value: "",
          factory_type_id: "",
          product_type_options: [
            {
              key: null,
              text: "",
              value: "",
              product_type_id: "",
              product_type_struct_id: "",
              brand_options: [
                {
                  key: null,
                  text: "",
                  value: "",
                  brand_id: ""
                }
              ]
            }
          ]
        }
      ],
      productTypeOptions: [],
      user_type: "",
      factory: ""
    };
  }

  async componentWillMount() {
    if (
      !this.props.requestRedux.params_request_id ||
      !this.props.requestRedux.params_company_id
    ) {
      var request_id = this.props.match.params.request_id;
      var company_id = this.props.match.params.company_id;

      await this.setState({
        params_request_id: request_id,
        params_company_id: company_id
      });
    }
    // console.log("will mount", this.props.requestRedux);
    // console.log("company_id", company_id, "\nrequest_id", request_id);
    this.setState({ loading: true });

    await this.setOption();

    if (
      this.state.params_request_id !== undefined &&
      this.state.params_company_id !== undefined &&
      this.state.requestStatus !== 4
    ) {
      Swal.fire({
        title: "คำขอนี้ถูกแก้ไขแล้ว",
        type: "warning",
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonColor: "#43A047",
        confirmButtonText: "ตกลง"
      }).then(async result => {
        if (result.value) {
          return <Redirect to="/company/main" />;
        }
      });
    }

    this.setState({ loading: false });

    // console.log("after will mount", this.state);
    this.setState({
      currentPage: "license"
    });
  }

  setOption = async () => {
    // console.log("klasjf;lkasjdfklj", this.props.requestRedux.companyId);
    if (!this.props.requestRedux.companyId) {
      let data;
      if (
        this.state.params_company_id === undefined &&
        this.state.params_request_id === undefined
      ) {
        // data = await JSON.parse(localStorage.getItem("company_data"));
        let company_data = await axios.get(GET_REQUEST_DATA_COMPANY, TOKEN_COMPANY());
        // console.log(data)

        data = company_data.data

      } else {
        let company_data = await axios.get(
          GET_REQUEST_DATA_BY_COMPANY_ID + this.state.params_company_id
        );
        data = company_data.data;
      }

      let importFactories = data.value.factories_import;
      let manufactFactories = data.value.factorise_manufacturer;

      let importFacArr = [];
      let manufactFacArr = [];

      if (importFactories !== null) {
        importFactories.map((factory, fact_idx) => {
          let f = {
            id: factory.id,
            key: fact_idx,
            text: factory.name_th,
            value: factory.id,
            factory_type_id: "2",
            description:
              factory.text_address !== ""
                ? factory.text_address
                : factory.address +
                " " +
                factory.soi +
                " " +
                factory.road +
                " " +
                factory.subdistrict +
                " " +
                factory.district +
                " " +
                factory.province +
                " " +
                factory.zipcode,
            product_type_options: factory.Products.map((product, prod_idx) => {
              return {
                id: product.id,
                key: prod_idx,
                text: product.product_struct,
                value: product.product_struct + "=>" + product.id,
                product_type_id: product.type_product_id.toString(),
                product_type_struct_id: product.type_struct_id.toString(),
                brand_options: product.Brands.map((brand, brand_idx) => {
                  return {
                    key: brand_idx,
                    text: brand.name,
                    value: parseInt(brand.id),
                    brand_id: brand.id
                  };
                })
              };
            })
          };
          return importFacArr.push(f);
        });
      }
      if (manufactFactories !== null) {
        manufactFactories.map((factory, fact_idx) => {
          let f = {
            id: factory.id,
            key: fact_idx,
            text: factory.name_th,
            value: factory.id,
            factory_type_id: "1",
            description:
              factory.text_address !== ""
                ? factory.text_address
                : factory.address +
                " " +
                factory.soi +
                " " +
                factory.road +
                " " +
                factory.subdistrict +
                " " +
                factory.district +
                " " +
                factory.province +
                " " +
                factory.zipcode,
            product_type_options: factory.Products.map((product, prod_idx) => {
              return {
                id: product.id,
                key: prod_idx,
                text: product.product_struct,
                value: product.product_struct + "=>" + product.id,
                product_type_id: product.type_product_id.toString(),
                product_type_struct_id: product.type_struct_id.toString(),
                brand_options: product.Brands.map((brand, brand_idx) => {
                  return {
                    key: brand_idx,
                    text: brand.name,
                    value: parseInt(brand.id),
                    brand_id: brand.id
                  };
                })
              };
            })
          };
          return manufactFacArr.push(f);
        });
      }
      this.props.requestRedux.factoryImportTypeOptions = importFacArr;
      this.props.requestRedux.factoryManufacturerTypeOptions = manufactFacArr;
      this.props.requestRedux.companyId = data.value.company[0].companies_id;

      if (this.props.requestRedux.factoryImportTypeOptions.length === 0) {
        this.props.requestRedux.user_type = "ผู้ผลิต";
      } else if (
        this.props.requestRedux.factoryManufacturerTypeOptions.length === 0
      ) {
        this.props.requestRedux.user_type = "ผู้นำเข้า";
      }
      // console.log("factories import", importFacArr);
      // console.log("factories manu", manufactFacArr);
      // if (this.props.requestRedux.productRequest.length !== 0) {
      //   if (this.props.requestRedux.productRequest[0].productName === "") {
      //     this.props.requestRedux.productRequest = [];
      //   }
      // }
      // await this.setState(this.props.requestRedux);
      // console.log("before getdraft", this.state);
      // Load draft section
    }
    // else {
    //   await this.setState(this.props.requestRedux);
    // }

    await this.setState(this.props.requestRedux);

    if (!this.state.isLoadFormCheck) {
      if (
        localStorage.getItem("draftId") !== null &&
        this.state.params_request_id === undefined &&
        this.state.params_company_id === undefined
      ) {
        await this.getDraft_license(localStorage.getItem("draftId"));
        this.setState({
          isOpenDraft: true
        });
      } else if (
        // localStorage.getItem("draftId") === null &&
        this.state.params_request_id !== undefined &&
        this.state.params_company_id !== undefined
      ) {
        await this.getRequestData(this.state.params_request_id);
        this.setState({
          isOpenDraft: true
        });
      }
      this.setState({
        isLoadFormCheck: true
      });
    }
    // // console.log(this.props.requestRedux)

    // console.log("this statedsadasdasdasdsad", this.state);
  };

  getDraft_license = async tyre_id => {
    try {
      // console.log("tyre_id yyyyyyyyyyyyyy", tyre_id);
      let draft_data = await axios.get(GET_DRAFT_DATA_BY_ID + tyre_id);
      let data = draft_data.data.value.tyre_requests;

      // console.log("draft head", draft_data);
      document.title = "ECO Tires " + data.tyre_requests_name;
      this.setState({ draftName: data.tyre_requests_name });
      let productArr = [];
      // let productRemove = [];
      let preState = this.state;
      let factory =
        data.factories.type_factories_id === "1"
          ? preState.factoryManufacturerTypeOptions
          : data.factories.type_factories_id === "2"
            ? preState.factoryImportTypeOptions
            : null;
      // console.log("factory let", factory);

      data.product_requests.forEach((product, prod_idx) => {
        // console.log("this.state.productRequest[prod_idx].testResult",  this.state.productRequest[prod_idx]);

        // // console.log(
        //   "license file ",
        //   this.state.productRequest[prod_idx].licenseNumberFile.data
        // );
        if (product.products.deleted_at === null) {
          let d = {
            id: product.id,
            productId: product.products_id,
            productTypeId: product.products.type_product_id.toString(),
            productTypeStructId: product.products.type_struct_id.toString(),
            productName: product.products.product_struct,
            license: "",
            licenseNumber: product.license_request,
            licenseType: product.selected_tisi_license_type,
            requestNO: product.request_no,
            licenseNumberFile: {
              name: product.file_filename_product_request,
              data: [],
              // this.state.productRequest[prod_idx].licenseNumberFile.data,
              path: product.file_path_product_request
            },
            listProduct: product.list_request,
            brandsRequest: product.brand_requests.filter((brand, brand_idx) => (
              // if (brand.brands.deleted_at !== null) {
              //   console.log('brand del', brand.brands.deleted_at)
              //   return false
              // }
              // ()
              !(brand.brands.deleted_at !== null)
            )).map((brand) => {

              return {
                id: brand.id,
                brandId: brand.brands_id,
                brandName: brand.brands.name,
                genRequest: brand.gen_requests.map((gen, gen_idx) => {
                  return {
                    id: gen.id,
                    genName: gen.gen_name,
                    genLayoutFile: {
                      name: gen.file_filename_gen_request,
                      data: [],
                      // this.state.productRequest[prod_idx].brandsRequest[
                      //   brand_idx
                      // ].genRequest[gen_idx].genLayoutFile.data,
                      path: gen.file_path_gen_request
                    },
                    sizeRequest: gen.size_requests.map((size, size_idx) => {
                      return {
                        id: size.id,
                        sizeGen: size.size_text,
                        sizeCapacity: size.load_capacity,
                        sizeSpeedSymbol: size.speed_symbol,
                        sizeSpecialFeature: size.special_feature,
                        sizeStructure: size.size_struct,
                        sizeTypeOfUse: size.type_use,
                        sizeCuttingWidth: size.cutting_width,
                        sizeSymbol: size.symbol
                      };
                    })
                  };
                })
              };
            }),
            testResult: {
              id: product.test_results.id,
              testName_a: "ค่าสัมประสิทธิ์ของความต้านทานการหมุน",
              testValue_a: product.test_results.rs_test_value.toString(),
              testUnit_a: "N/kN",
              brandName_a: product.test_results.rs_brand_request_value,
              genName_a: product.test_results.rs_gen_request_value,
              sizeGen_a: product.test_results.rs_size_request_value,
              testPage_a: product.test_results.rs_test_page,
              testFile_a: {
                name: product.test_results.rs_file_filename,
                data:
                  this.state.productRequest[prod_idx] !== undefined
                    ? this.state.productRequest[prod_idx].testResult.testFile_a
                      .data
                    : [],
                path: product.test_results.rs_file_path
              },

              testName_b: "ค่าดัชนีของการยึดเกาะถนนบนพื้นเปียก",
              testValue_b: product.test_results.rg_test_value.toString(),
              testUnit_b: "G",
              brandName_b: product.test_results.rg_brand_request_value,
              genName_b: product.test_results.rg_gen_request_value,
              sizeGen_b: product.test_results.rg_size_request_value,
              testPage_b: product.test_results.rg_test_page,
              testFile_b: {
                name: product.test_results.rg_file_filename,
                data:
                  this.state.productRequest[prod_idx] !== undefined
                    ? this.state.productRequest[prod_idx].testResult.testFile_b
                      .data
                    : [],
                path: product.test_results.rg_file_path
              },

              testName_c: "ค่าระดับเสียงจากยางล้อที่สัมผัสผิวถนน",
              testValue_c: product.test_results.tr_test_value.toString(),
              testUnit_c: "dB(A)",
              brandName_c: product.test_results.tr_brand_request_value,
              genName_c: product.test_results.tr_gen_request_value,
              sizeGen_c: product.test_results.tr_size_request_value,
              testPage_c: product.test_results.tr_test_page,
              testFile_c: {
                name: product.test_results.tr_file_filename,
                data:
                  this.state.productRequest[prod_idx] !== undefined
                    ? this.state.productRequest[prod_idx].testResult.testFile_c
                      .data
                    : [],
                path: product.test_results.tr_file_path
              },

              testName_d: "ค่าดัชนีการเกาะถนนหิมะ",
              testValue_d: product.test_results.sr_test_value.toString(),
              testUnit_d: "SG",
              brandName_d: product.test_results.sr_brand_request_value,
              genName_d: product.test_results.sr_gen_request_value,
              sizeGen_d: product.test_results.sr_size_request_value,
              testPage_d: product.test_results.sr_test_page,
              testFile_d: {
                name: product.test_results.sr_file_filename,
                data:
                  this.state.productRequest[prod_idx] !== undefined
                    ? this.state.productRequest[prod_idx].testResult.testFile_d
                      .data
                    : [],
                path: product.test_results.sr_file_path
              }
            }
          };
          productArr.push(d);
        } else {
          // productRemove.push(product.id)
        }
      });

      // // console.log("SJDKFJSLKDJFKLSJFKLSJFLKSDFKLSDJFKL:S",data.id)
      preState.tyre_requests_id = data.id;
      // // console.log("preState.tyre_requests_id", preState.tyre_requests_id);
      preState.user_type =
        data.factories.type_factories_id === "1"
          ? "ผู้ผลิต"
          : data.factories.type_factories_id === "2"
            ? "ผู้นำเข้า"
            : "";
      preState.productTypeOptions =
        factory !== null
          ? factory.find(f => f.id === data.factories_id).product_type_options
          : [];
      preState.requestStatus = data.request_status;

      if (data.factories.deleted_at === null) {
        preState.factory = data.factories_id;
      }

      preState.factoryTypeId = data.factories.type_factories_id;
      preState.factoryId = data.factories_id;

      if (productArr.length !== 0) {
        preState.productRequest = productArr;
      }

      // // console.log("draft", draft_data.data.value);
      await this.setState(preState);
      // await this.setState({productRemove : productRemove})


      return true;
    } catch (error) {
      console.log(error);
      Swal.fire({
        type: "error",
        title: "ผิดพลาด",
        text: "เกิดข้อผิดพลาดในการดึงข้อมูล",
        confirmButtonText: "ตกลง"
      });
      return false;
    }
  };

  getRequestData = async request_id => {
    try {
      // console.log("tyre_id yyyyyyyyyyyyyy", tyre_id);
      let draft_data = await axios.get(GET_REQUEST_DATA_BY_ID + request_id);
      let data = draft_data.data.value.tyre_requests;
      // console.log("draft head",  data);

      this.setState({ draftName: data.tyre_requests_name });
      let productArr = [];
      let preState = this.state;
      let factory =
        data.factories.type_factories_id === "1"
          ? preState.factoryManufacturerTypeOptions
          : data.factories.type_factories_id === "2"
            ? preState.factoryImportTypeOptions
            : null;
      // console.log("factory let", factory);
      // console.log(this.state);

      data.product_requests.forEach((product, prod_idx) => {
        let d = {
          id: product.id,
          productId: product.products_id,
          productTypeId: product.products.type_product_id.toString(),
          productName: product.products.product_struct,
          license: "",
          licenseNumber: product.license_request,
          licenseNumberFile: {
            name: product.file_filename_product_request,
            data: [],
            // this.state.productRequest[prod_idx].licenseNumberFile.data,
            path: product.file_path_product_request
          },
          listProduct: product.list_request,
          brandsRequest: product.brand_requests.map((brand, brand_idx) => {
            return {
              id: brand.id,
              brandId: brand.brands_id,
              brandName: brand.brands.name,
              genRequest: brand.gen_requests.map((gen, gen_idx) => {
                return {
                  id: gen.id,
                  genName: gen.gen_name,
                  genLayoutFile: {
                    name: gen.file_filename_gen_request,
                    data: [],
                    // this.state.productRequest[prod_idx].brandsRequest[
                    //   brand_idx
                    // ].genRequest[gen_idx].genLayoutFile.data,
                    path: gen.file_path_gen_request
                  },
                  sizeRequest: gen.size_requests.map((size, size_idx) => {
                    return {
                      id: size.id,
                      sizeGen: size.size_text,
                      sizeCapacity: size.load_capacity,
                      sizeSpeedSymbol: size.speed_symbol,
                      sizeSpecialFeature: size.special_feature,
                      sizeStructure: size.size_struct,
                      sizeTypeOfUse: size.type_use,
                      sizeCuttingWidth: size.cutting_width,
                      sizeSymbol: size.symbol
                    };
                  })
                };
              })
            };
          }),
          testResult: {
            id: product.test_results.id,
            testName_a: "ค่าสัมประสิทธิ์ของความต้านทานการหมุน",
            testValue_a: product.test_results.rs_test_value.toString(),
            testUnit_a: "N/kN",
            brandName_a: product.test_results.rs_brand_request_value,
            genName_a: product.test_results.rs_gen_request_value,
            sizeGen_a: product.test_results.rs_size_request_value,
            testPage_a: product.test_results.rs_test_page,
            testFile_a: {
              name: product.test_results.rs_file_filename,
              data:
                this.state.productRequest[prod_idx] !== undefined
                  ? this.state.productRequest[prod_idx].testResult.testFile_a
                    .data
                  : [],
              path: product.test_results.rs_file_path
            },

            testName_b: "ค่าดัชนีของการยึดเกาะถนนบนพื้นเปียก",
            testValue_b: product.test_results.rg_test_value.toString(),
            testUnit_b: "G",
            brandName_b: product.test_results.rg_brand_request_value,
            genName_b: product.test_results.rg_gen_request_value,
            sizeGen_b: product.test_results.rg_size_request_value,
            testPage_b: product.test_results.rg_test_page,
            testFile_b: {
              name: product.test_results.rg_file_filename,
              data:
                this.state.productRequest[prod_idx] !== undefined
                  ? this.state.productRequest[prod_idx].testResult.testFile_b
                    .data
                  : [],
              path: product.test_results.rg_file_path
            },

            testName_c: "ค่าระดับเสียงจากยางล้อที่สัมผัสผิวถนน",
            testValue_c: product.test_results.tr_test_value.toString(),
            testUnit_c: "dB(A)",
            brandName_c: product.test_results.tr_brand_request_value,
            genName_c: product.test_results.tr_gen_request_value,
            sizeGen_c: product.test_results.tr_size_request_value,
            testPage_c: product.test_results.tr_test_page,
            testFile_c: {
              name: product.test_results.tr_file_filename,
              data:
                this.state.productRequest[prod_idx] !== undefined
                  ? this.state.productRequest[prod_idx].testResult.testFile_c
                    .data
                  : [],
              path: product.test_results.tr_file_path
            },

            testName_d: "ค่าดัชนีการเกาะถนนหิมะ",
            testValue_d: product.test_results.sr_test_value.toString(),
            testUnit_d: "SG",
            brandName_d: product.test_results.sr_brand_request_value,
            genName_d: product.test_results.sr_gen_request_value,
            sizeGen_d: product.test_results.sr_size_request_value,
            testPage_d: product.test_results.sr_test_page,
            testFile_d: {
              name: product.test_results.sr_file_filename,
              data:
                this.state.productRequest[prod_idx] !== undefined
                  ? this.state.productRequest[prod_idx].testResult.testFile_d
                    .data
                  : [],
              path: product.test_results.sr_file_path
            }

            // testFile: {
            //   name: product.test_results.file_filename_test_result,
            //   data: [],
            //   path: product.test_results.file_path_test_result
            // },
            // testPage: product.test_results.test_page
          }
        };
        productArr.push(d);
      });

      preState.tyre_requests_id = data.id;
      // // console.log("preState.tyre_requests_id", preState.tyre_requests_id);
      preState.user_type =
        data.factories.type_factories_id === "1"
          ? "ผู้ผลิต"
          : data.factories.type_factories_id === "2"
            ? "ผู้นำเข้า"
            : "";
      preState.productTypeOptions =
        factory !== null
          ? factory.find(f => f.id === data.factories_id).product_type_options
          : [];
      preState.requestStatus = data.request_status;
      preState.factory = data.factories.name_th + "=>" + data.factories_id;
      preState.factoryTypeId = data.factories.type_factories_id;
      preState.factoryId = data.factories_id;
      preState.productRequest = productArr;

      // // console.log("draft", draft_data.data.value);
      await this.setState(preState);

      // console.log("success", this.state);

      return true;
    } catch (error) {
      console.log(error);
      Swal.fire({
        type: "error",
        title: "ผิดพลาด",
        text: "เกิดข้อผิดพลาดในการดึงข้อมูล",
        confirmButtonText: "ตกลง"
      });
      return false;
    }
  };

  render() {
    var productPos = this.state.productPos;

    var tmp = this.state.productRequest;

    return (
      <div>
        {this.state.params_request_id === undefined &&
          this.state.params_company_id === undefined ? (
            <Headers />
          ) : (
            <span />
          )}
        <span id="headPage"></span>

        <Container style={{ margin: 30 }}>
          <br />
          {(() => {
            if (this.state.draftName) {
              return (
                <Segment style={{ margin: 0 }} compact>
                  <h2>ฉบับร่าง: {this.state.draftName}</h2>
                </Segment>
              );
            } else {
              return <span />;
            }
          })()}
          <Segment vertical style={{ margin: 0, padding: 0 }}>
            <Dimmer inverted active={this.state.loading}>
              <Loader inverted>Loading</Loader>
            </Dimmer>
            <Segment padded color={theme_form_color_1}>
              <Header>
                <h1>
                  <Icon.Group>
                    <Icon color={theme_form_color_1} name="drivers license" />
                    <Icon
                      color={theme_form_color_1}
                      corner="bottom right"
                      name="hand paper"
                    />
                  </Icon.Group>
                  กรอกข้อมูล ECO Sticker
                </h1>
              </Header>
              <Divider />
              <Form error={!this.state.validForms}>
                <Message
                  error
                  header="กรอกข้อมูลไม่ครบถ้วน"
                  content="กรุณาตรวจสอบข้อมูลใหม่อีกครั้ง"
                />
                <Segment padded="very">
                  <Grid>
                    {this.state.factoryImportTypeOptions.length > 0 &&
                      this.state.factoryManufacturerTypeOptions.length > 0 ? (
                        <Grid.Row>
                          <Grid.Column mobile={16} tablet={16} computer={16}>
                            <Form.Field error={this.state.user_type_error}>
                              <label>ประเภท</label>

                              <Dropdown
                                id="user_type"
                                fluid
                                size="large"
                                placeholder="ประเภท"
                                clearable
                                selection
                                options={user_type_options}
                                value={this.state.user_type}
                                onChange={async (e, { value }) => {
                                  await this.setState({
                                    user_type: value,
                                    factory: "",
                                    productRequest: [
                                      {
                                        id: "",
                                        productId: "",
                                        productTypeId: "",
                                        productName: "",
                                        licenseNumber: "",
                                        licenseNumberFile: {
                                          name: "",
                                          data: [],
                                          path: ""
                                        },
                                        listProduct: "",
                                        brandsRequest: [
                                          {
                                            id: "",
                                            brandId: "",
                                            brandName: "",
                                            genRequest: [
                                              {
                                                id: "",
                                                genName: "",
                                                genLayoutFile: {
                                                  name: "",
                                                  data: [],
                                                  path: ""
                                                },
                                                sizeRequest: [
                                                  {
                                                    id: "",
                                                    sizeGen: "",
                                                    sizeCapacity: "",
                                                    sizeSpeedSymbol: "",
                                                    sizeSpecialFeature: "",
                                                    sizeStructure: "",
                                                    sizeTypeOfUse: "",
                                                    sizeCuttingWidth: "",
                                                    sizeSymbol: ""
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        ],
                                        testResult: {
                                          id: "",
                                          testName_a:
                                            "ค่าสัมประสิทธิ์ของความต้านทานการหมุน",
                                          testValue_a: "0",
                                          testUnit_a: "N/kN",
                                          brandName_a: "",
                                          genName_a: "",
                                          sizeGen_a: "",
                                          sizeSymbol_a: "",
                                          testPage_a: "",
                                          testFile_a: {
                                            name: "",
                                            data: [],
                                            path: ""
                                          },

                                          testName_b:
                                            "ค่าดัชนีของการยึดเกาะถนนบนพื้นเปียก",
                                          testValue_b: "0",
                                          testUnit_b: "G",
                                          brandName_b: "",
                                          genName_b: "",
                                          sizeGen_b: "",
                                          testPage_b: "",
                                          testFile_b: {
                                            name: "",
                                            data: [],
                                            path: ""
                                          },

                                          testName_c:
                                            "ค่าระดับเสียงจากยางล้อที่สัมผัสผิวถนน",
                                          testValue_c: "0",
                                          testUnit_c: "dB(A)",
                                          brandName_c: "",
                                          genName_c: "",
                                          sizeGen_c: "",
                                          testPage_c: "",
                                          testFile_c: {
                                            name: "",
                                            data: [],
                                            path: ""
                                          },

                                          testName_d: "ค่าดัชนีการเกาะถนนหิมะ",
                                          testValue_d: "0",
                                          testUnit_d: "SG",
                                          brandName_d: "",
                                          genName_d: "",
                                          sizeGen_d: "",
                                          testPage_d: "",
                                          testFile_d: {
                                            name: "",
                                            data: [],
                                            path: ""
                                          }
                                        }
                                      }
                                    ]

                                    // productRequest: product_request_template
                                  });
                                }}
                              />
                            </Form.Field>
                          </Grid.Column>
                        </Grid.Row>
                      ) : null}

                    <Grid.Row>
                      <Grid.Column mobile={16} tablet={16} computer={16}>
                        <Form.Field error={this.state.factory_error}>
                          <label>โรงงาน</label>

                          <Dropdown
                            id="factory"
                            fluid
                            selection
                            size="large"
                            placeholder="โรงงาน"
                            clearable
                            options={
                              (this.state.user_type === "ผู้ผลิต"
                                ? this.state.factoryManufacturerTypeOptions
                                : this.state.factoryImportTypeOptions) || []
                            }
                            value={this.state.factory}
                            disabled={this.state.user_type === ""}
                            onChange={async (e, { value }) => {
                              let factory =
                                this.state.user_type === "ผู้ผลิต"
                                  ? this.state.factoryManufacturerTypeOptions
                                  : this.state.factoryImportTypeOptions;
                              let productOpt = [];
                              let factoryTypeId = "";
                              let factoryId = "";
                              // let factoryName = "";
                              let tmp_product_remove = this.state.productRemove;
                              let tmp_product_request = this.state.productRequest;
                              factory.map(factory => {
                                if (factory.value === value) {
                                  productOpt = factory.product_type_options;
                                  factoryTypeId = factory.factory_type_id;
                                  factoryId = factory.id;
                                  // factoryName = factory.text;
                                }
                                return "";
                              });

                              tmp_product_request.forEach((product, index) => {
                                if (product.id) {
                                  tmp_product_remove.push(product.id);
                                }
                              });

                              await this.setState({
                                factory: value,
                                productTypeOptions: productOpt,
                                factoryTypeId: factoryTypeId,
                                factoryId: factoryId,
                                productRemove: tmp_product_remove,
                                productRequest: [
                                  {
                                    id: "",
                                    productId: "",
                                    productTypeId: "",
                                    productName: "",
                                    licenseNumber: "",
                                    licenseNumberFile: {
                                      name: "",
                                      data: [],
                                      path: ""
                                    },
                                    listProduct: "",
                                    brandsRequest: [
                                      {
                                        id: "",
                                        brandId: "",
                                        brandName: "",
                                        genRequest: [
                                          {
                                            id: "",
                                            genName: "",
                                            genLayoutFile: {
                                              name: "",
                                              data: [],
                                              path: ""
                                            },
                                            sizeRequest: [
                                              {
                                                id: "",
                                                sizeGen: "",
                                                sizeCapacity: "",
                                                sizeSpeedSymbol: "",
                                                sizeSpecialFeature: "",
                                                sizeStructure: "",
                                                sizeTypeOfUse: "",
                                                sizeCuttingWidth: "",
                                                sizeSymbol: ""
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ],
                                    testResult: {
                                      id: "",
                                      testName_a:
                                        "ค่าสัมประสิทธิ์ของความต้านทานการหมุน",
                                      testValue_a: "0",
                                      testUnit_a: "N/kN",
                                      brandName_a: "",
                                      genName_a: "",
                                      sizeGen_a: "",
                                      sizeSymbol_a: "",
                                      testPage_a: "",
                                      testFile_a: {
                                        name: "",
                                        data: [],
                                        path: ""
                                      },

                                      testName_b:
                                        "ค่าดัชนีของการยึดเกาะถนนบนพื้นเปียก",
                                      testValue_b: "0",
                                      testUnit_b: "G",
                                      brandName_b: "",
                                      genName_b: "",
                                      sizeGen_b: "",
                                      testPage_b: "",
                                      testFile_b: {
                                        name: "",
                                        data: [],
                                        path: ""
                                      },

                                      testName_c:
                                        "ค่าระดับเสียงจากยางล้อที่สัมผัสผิวถนน",
                                      testValue_c: "0",
                                      testUnit_c: "dB(A)",
                                      brandName_c: "",
                                      genName_c: "",
                                      sizeGen_c: "",
                                      testPage_c: "",
                                      testFile_c: {
                                        name: "",
                                        data: [],
                                        path: ""
                                      },

                                      testName_d: "ค่าดัชนีการเกาะถนนหิมะ",
                                      testValue_d: "0",
                                      testUnit_d: "SG",
                                      brandName_d: "",
                                      genName_d: "",
                                      sizeGen_d: "",
                                      testPage_d: "",
                                      testFile_d: {
                                        name: "",
                                        data: [],
                                        path: ""
                                      }
                                    }
                                  }
                                ]
                                // productRequest: product_request_template
                              });
                              // console.log("this factory", this.state);
                            }}
                          />
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Grid>
                    <Grid.Row>
                      <Grid.Column mobile={16} tablet={16} computer={16}>
                        <Form.Field error={this.state.product_type_error}>
                          <label>ผลิตภัณฑ์</label>
                          <Dropdown
                            id={"product_type"}
                            multiple
                            fluid
                            selection
                            size="large"
                            placeholder="เลือกผลิตภัณฑ์"
                            clearable
                            options={this.state.productTypeOptions}
                            value={this.state.productRequest.map(
                              a => a.productName + "=>" + a.productId
                            )}
                            disabled={this.state.factory === ""}
                            onChange={async (e, data) => {
                              var value = data.value;
                              // console.log("drop value", value)
                              var product = this.state.productTypeOptions.find(
                                p => p.value === value[value.length - 1]
                              );
                              if (
                                this.state.productRequest.length < value.length
                              ) {
                                // console.log("add");
                                let tempProduct = this.state.productRequest;
                                let productRemove = this.state.productRemove;
                                let productDetail = {
                                  id: "",
                                  productId: product.id,
                                  productTypeId: product.product_type_id.toString(),
                                  productTypeStructId: product.product_type_struct_id,
                                  productName: product.text,
                                  licenseNumber: "",
                                  licenseType: null,
                                  licenseNumberFile: {
                                    name: "",
                                    data: [],
                                    path: ""
                                  },
                                  listProduct: "",
                                  requestNO: '',
                                  brandsRequest: [],

                                  testResult: {
                                    id: "",
                                    testName_a:
                                      "ค่าสัมประสิทธิ์ของความต้านทานการหมุน",
                                    testValue_a: "0",
                                    testUnit_a: "N/kN",
                                    brandName_a: "",
                                    genName_a: "",
                                    sizeGen_a: "",
                                    sizeSymbol_a: "",
                                    testPage_a: "",
                                    testFile_a: {
                                      name: "",
                                      data: [],
                                      path: ""
                                    },

                                    testName_b:
                                      "ค่าดัชนีของการยึดเกาะถนนบนพื้นเปียก",
                                    testValue_b: "0",
                                    testUnit_b: "G",
                                    brandName_b: "",
                                    genName_b: "",
                                    sizeGen_b: "",
                                    testPage_b: "",
                                    testFile_b: {
                                      name: "",
                                      data: [],
                                      path: ""
                                    },

                                    testName_c:
                                      "ค่าระดับเสียงจากยางล้อที่สัมผัสผิวถนน",
                                    testValue_c: "0",
                                    testUnit_c: "dB(A)",
                                    brandName_c: "",
                                    genName_c: "",
                                    sizeGen_c: "",
                                    testPage_c: "",
                                    testFile_c: {
                                      name: "",
                                      data: [],
                                      path: ""
                                    },

                                    testName_d: "ค่าดัชนีการเกาะถนนหิมะ",
                                    testValue_d: "0",
                                    testUnit_d: "SG",
                                    brandName_d: "",
                                    genName_d: "",
                                    sizeGen_d: "",
                                    testPage_d: "",
                                    testFile_d: {
                                      name: "",
                                      data: [],
                                      path: ""
                                    }
                                  }
                                };
                                if (tempProduct[0].productName === "") {
                                  if (tempProduct[0].id) {
                                    productRemove.push(tempProduct[0].id);
                                  }
                                  tempProduct[0] = productDetail;
                                } else {
                                  tempProduct.push(productDetail);
                                }

                                await this.setState({
                                  productRequest: tempProduct,
                                  productRemove: productRemove
                                });
                                // // console.log(
                                //   "after set prodReq",
                                //   this.state.productRequest
                                // );
                                // // console.log("this state", this.state);
                              } else {
                                let indexRemove = [];
                                let tmp_product_request = this.state
                                  .productRequest;
                                let tmp_product_remove = this.state
                                  .productRemove;
                                // console.log("removeddddd");
                                if (value.length === 0) {
                                  if (tmp_product_request.length === 1) {
                                    if (tmp_product_request[0].id) {
                                      tmp_product_remove.push(
                                        tmp_product_request[0].id
                                      );
                                    }
                                  } else {
                                    tmp_product_request.forEach(
                                      (product, index) => {
                                        if (product.id) {
                                          tmp_product_remove.push(product.id);
                                        }
                                      }
                                    );
                                  }
                                  let productRequest = [
                                    {
                                      id: "",
                                      productId: "",
                                      productTypeId: "",
                                      productName: "",
                                      licenseNumber: "",
                                      licenseNumberFile: {
                                        name: "",
                                        data: [],
                                        path: ""
                                      },
                                      listProduct: "",
                                      brandsRequest: [],

                                      testResult: {
                                        id: "",
                                        testName_a:
                                          "ค่าสัมประสิทธิ์ของความต้านทานการหมุน",
                                        testValue_a: "0",
                                        testUnit_a: "N/kN",
                                        brandName_a: "",
                                        genName_a: "",
                                        sizeGen_a: "",
                                        sizeSymbol_a: "",
                                        testPage_a: "",
                                        testFile_a: {
                                          name: "",
                                          data: [],
                                          path: ""
                                        },

                                        testName_b:
                                          "ค่าดัชนีของการยึดเกาะถนนบนพื้นเปียก",
                                        testValue_b: "0",
                                        testUnit_b: "G",
                                        brandName_b: "",
                                        genName_b: "",
                                        sizeGen_b: "",
                                        testPage_b: "",
                                        testFile_b: {
                                          name: "",
                                          data: [],
                                          path: ""
                                        },

                                        testName_c:
                                          "ค่าระดับเสียงจากยางล้อที่สัมผัสผิวถนน",
                                        testValue_c: "0",
                                        testUnit_c: "dB(A)",
                                        brandName_c: "",
                                        genName_c: "",
                                        sizeGen_c: "",
                                        testPage_c: "",
                                        testFile_c: {
                                          name: "",
                                          data: [],
                                          path: ""
                                        },

                                        testName_d: "ค่าดัชนีการเกาะถนนหิมะ",
                                        testValue_d: "0",
                                        testUnit_d: "SG",
                                        brandName_d: "",
                                        genName_d: "",
                                        sizeGen_d: "",
                                        testPage_d: "",
                                        testFile_d: {
                                          name: "",
                                          data: [],
                                          path: ""
                                        }
                                      }
                                    }
                                  ];

                                  await this.setState({
                                    productRequest: productRequest,
                                    productRemove: tmp_product_remove
                                  });
                                } else {
                                  this.state.productRequest.forEach(
                                    (product, index) => {
                                      if (
                                        !value.includes(
                                          product.productName +
                                          "=>" +
                                          product.productId
                                        )
                                      ) {
                                        indexRemove.push(index);
                                      }
                                    }
                                  );
                                  if (tmp_product_request[indexRemove[0]].id) {
                                    tmp_product_remove.push(
                                      tmp_product_request[indexRemove[0]].id
                                    );
                                  }
                                  tmp_product_request.splice(indexRemove[0], 1);

                                  // // console.log("indexREmove", indexRemove[0]);
                                  await this.setState({
                                    productRequest: tmp_product_request,
                                    productRemove: tmp_product_remove
                                  });
                                  // // console.log("remove", this.state);

                                  // // console.log(
                                  //   "removed product",
                                  //   this.state.productRemove
                                  // );
                                }
                              }
                              // console.log("after product onChange", this.state);
                            }}
                          />
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>

                    {// this.state.productRequest[0].productName === '' ? null :
                      this.state.productRequest[0].productName === ""
                        ? null
                        : this.state.productRequest.map((brand, brand_index) => (
                          <Grid.Row key={brand_index}>
                            <Grid.Column mobile={16} tablet={16} computer={16}>
                              <Form.Field
                                error={
                                  this.state.productRequest[brand_index]
                                    .brand_name_error
                                }
                              >
                                <label>ชื่อทางการค้า {brand.productName}</label>
                                <Dropdown
                                  id={"trade_name_" + brand_index}
                                  multiple
                                  selection
                                  fluid
                                  size="large"
                                  placeholder="เลือกชื่อทางการค้า"
                                  clearable
                                  options={
                                    // []
                                    this.state.productRequest[0].productName ===
                                      ""
                                      ? []
                                      : this.state.productTypeOptions.find(
                                        p =>
                                          p.value === brand.productName + "=>" + brand.productId


                                      ).brand_options || []

                                    //this.state.productTypeOptions[brand_index].brand_options
                                  }
                                  value={brand.brandsRequest.map(
                                    a => a.brandId
                                  )}
                                  onChange={async (e, { value }) => {
                                    var brandDetail = this.state.productTypeOptions
                                      .find(
                                        p =>
                                          p.value ===
                                          brand.productName +
                                          "=>" +
                                          brand.productId
                                      )
                                      .brand_options.find(
                                        b => b.value === value[value.length - 1]
                                      );
                                    if (
                                      brand.brandsRequest.length < value.length
                                    ) {
                                      // console.log("add brand",brandDetail);
                                      var tmp = this.state.productRequest;
                                      var detail = {
                                        id: "",
                                        brandId: parseInt(brandDetail.brand_id),
                                        brandName: brandDetail.text,
                                        genRequest: [
                                          {
                                            id: "",
                                            genName: "",
                                            genLayoutFile: {
                                              name: "",
                                              data: [],
                                              path: ""
                                            },
                                            sizeRequest: [
                                              {
                                                id: "",
                                                sizeGen: "",
                                                sizeCapacity: "",
                                                sizeSpeedSymbol: "",
                                                sizeSpecialFeature: "",
                                                sizeStructure: "",
                                                sizeTypeOfUse: "",
                                                sizeCuttingWidth: "",
                                                sizeSymbol: ""
                                              }
                                            ]
                                          }
                                        ]
                                      };
                                      tmp[brand_index].brandsRequest.push(
                                        detail
                                      );

                                      await this.setState({
                                        productRequest: tmp
                                      });
                                      // // console.log("brand set state", this.state);
                                    } else {
                                      let tmp_product_request = this.state
                                        .productRequest;
                                      let indexRemove = [];
                                      let brandRemove = this.state.brandRemove;
                                      if (value.length === 0) {
                                        if (
                                          tmp_product_request[brand_index]
                                            .brandsRequest.length === 1
                                        ) {
                                          if (
                                            tmp_product_request[brand_index]
                                              .brandsRequest[0].id
                                          ) {
                                            brandRemove.push(
                                              tmp_product_request[brand_index]
                                                .brandsRequest[0].id
                                            );
                                          }
                                        } else {
                                          tmp_product_request[
                                            brand_index
                                          ].brandsRequest.forEach(b => {
                                            if (b.id) {
                                              brandRemove.push(b.id);
                                            }
                                          });
                                        }
                                        tmp_product_request[
                                          brand_index
                                        ].brandsRequest = [];
                                        await this.setState({
                                          productRequest: tmp_product_request,
                                          brandRemove: brandRemove
                                        });
                                        // console.log(this.state);
                                      } else {
                                        // // console.log("remove brands");

                                        brand.brandsRequest.forEach(
                                          (b, index) => {
                                            if (!value.includes(b.brandId)) {
                                              indexRemove.push(index);
                                            }
                                          }
                                        );
                                        if (
                                          tmp_product_request[brand_index]
                                            .brandsRequest[indexRemove].id
                                        ) {
                                          brandRemove.push(
                                            tmp_product_request[brand_index]
                                              .brandsRequest[indexRemove].id
                                          );
                                        }
                                        tmp_product_request[
                                          brand_index
                                        ].brandsRequest.splice(indexRemove, 1);

                                        await this.setState({
                                          productRequest: tmp_product_request
                                        });
                                        // // console.log(this.state);
                                      }
                                    }
                                    // document.getElementById("saveAsButton").click();
                                  }}
                                />
                              </Form.Field>
                            </Grid.Column>
                          </Grid.Row>
                        ))}
                  </Grid>
                  <br />

                  <br />
                </Segment>

                <br />
              </Form>
            </Segment>

            <Requestbutton
              history={this.props.history}
              pos={productPos}
              data={this.state}
              url={"requestLicense"} // this.constructor.name
              productLength={tmp.length}
              callBackParent={this.recieveProps}
            />

            <br /> <br />
          </Segment>
        </Container>
      </div>
    );
  }

  recieveProps = childData => {

    this.setState(childData);
  };
}

export default connect(mapStateToProps)(requestLicense);
