import React, { Component } from "react";
import {
  Segment,
  Icon,
  Container,
  Table,
  Menu,
  Button,
  Dropdown,
  Pagination,
  Dimmer,
  Loader,
  Accordion,
  Image,
  Grid,
  Modal,
} from "semantic-ui-react";
import axios from "axios";
import { connect } from "react-redux"; // connect redux
import { fetchRequest } from "../actions/actions";
import ModalUploadFile from "./ModalUploadFile";
import AssesmentModal from "../AssesmentModal";

import {
  TOKEN_COMPANY,
  GET_DRAFT_ALL_DATA_COMPANY,
  DELETE_DRAFT_DATA_BY_ID,
  GET_REQUEST_DATA_COMPANY,
  GET_REQUEST_WAITING_FOR_APPROVAL_DATA_COMPANY,
  GET_REQUEST_APPROVE_DATA_COMPANY,
  GET_REQUEST_NOT_ALLOWED_DATA_COMPANY,
  GET_REQUEST_EDIT_COMPANY,
  DELETE_REQUEST_DATA_BY_ID,
} from "../router";
import Headers from "../header/requestHeader";
import Swal from "sweetalert2";

const requestPreviewPath = `/company/preview-request`;
const requestLicensePath = `/company/request-license`;

class requestIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      modalData: [],
      activeIndex: -1,
      isDeleteActive: false,
      login: true,
      activeItem: "ฐานข้อมูล Eco Sticker",
      data: [],
      rowQty: 10,
      currentPage: 1,
      pageQty: 1,
      allSizeRow: [
        { key: 1, text: 5, value: 5 },
        { key: 2, text: 10, value: 10 },
        { key: 3, text: 20, value: 20 },
      ],
      loader_table: false,
      loader_all: false,
      newRequest: {
        refCode: "",
        companyId: "",
        factoryId: "",
        factoryTypeId: "",
        tyre_requests_name: "",

        productRemove: [],
        brandRemove: [],
        genRemove: [],
        sizeRemove: [],
        productPos: 0,
        productRequest: [
          {
            id: "",
            productId: "",
            productTypeId: "",
            productName: "",
            licenseNumber: "",
            licenseNumberFile: { name: "", data: [], path: "" },
            listProduct: "",
            brandsRequest: [
              {
                id: "",
                brandId: "",
                brandName: "",
                genRequest: [
                  {
                    id: "",
                    genName: "",
                    genLayoutFile: { name: "", data: [], path: "" },
                    sizeRequest: [
                      {
                        id: "",
                        sizeGen: "",
                        sizeCapacity: "",
                        sizeSpeedSymbol: "",
                        sizeSpecialFeature: "",
                        sizeStructure: "",
                        sizeTypeOfUse: "",
                        sizeCuttingWidth: "",
                        sizeSymbol: "",
                      },
                    ],
                  },
                ],
              },
            ],
            testResult: {
              id: "",
              testName_a: "ค่าสัมประสิทธิ์ของความต้านทานการหมุน",
              testValue_a: "0",
              testUnit_a: "N/kN",
              brandName_a: "",
              genName_a: "",
              sizeGen_a: "",
              sizeSymbol_a: "",
              testPage_a: "",
              testFile_a: { name: "", data: [], path: "" },

              testName_b: "ค่าดัชนีของการยึดเกาะถนนบนพื้นเปียก",
              testValue_b: "0",
              testUnit_b: "G",
              brandName_b: "",
              genName_b: "",
              sizeGen_b: "",
              testPage_b: "",
              testFile_b: { name: "", data: [], path: "" },

              testName_c: "ค่าระดับเสียงจากยางล้อที่สัมผัสผิวถนน",
              testValue_c: "0",
              testUnit_c: "dB(A)",
              brandName_c: "",
              genName_c: "",
              sizeGen_c: "",
              testPage_c: "",
              testFile_c: { name: "", data: [], path: "" },

              testName_d: "ค่าดัชนีการเกาะถนนหิมะ",
              testValue_d: "0",
              testUnit_d: "SG",
              brandName_d: "",
              genName_d: "",
              sizeGen_d: "",
              testPage_d: "",
              testFile_d: { name: "", data: [], path: "" },
            },
          },
        ],
        shouldShowPopup: false,
      },
    };
  }

  componentWillMount = async () => {
    // console.log(this.state.login)
    if (localStorage.getItem("role") !== null) {
      this.setState({
        loader_table: true,
      });

      await this.getRequestData();
      // await this.getDataCompany();

      this.setState({ loader_table: false });
    }
  };

  componentDidMount = () => {
    const shouldOpen = localStorage.getItem("should_show_popup") === "true";
    this.setState({
      shouldShowPopup: shouldOpen,
    });
  };

  getRequestData = async (page = 1) => {
    try {
      let { data } = await axios.get(
        GET_REQUEST_APPROVE_DATA_COMPANY,
        TOKEN_COMPANY()
      );
      // console.log("data", status);
      if (data.value !== null) {
        await this.setState({ data: data.value });
        let lengthData = 1;
        if (this.state.data !== null) {
          lengthData = this.state.data.length;
        }
        let pageQty = lengthData / this.state.rowQty;
        await this.setState({ pageQty: Math.ceil(pageQty), currentPage: page });
      } else {
        await this.setState({ loader_table: false });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        type: "error",
        title: "ผิดพลาด",
        text: "เกิดข้อผิดพลาดในการดึงข้อมูล",
        confirmButtonText: "ตกลง",
      });
    }
  };

  getDataCompany = async () => {
    // console.log(localStorage.getItem("company_data"));
    if (localStorage.getItem("company_data") === null) {
      // console.log("object");
      try {
        let { data } = await axios.get(
          GET_REQUEST_DATA_COMPANY,
          TOKEN_COMPANY()
        );
        //console.log("get data ", data);
        localStorage.setItem("company_data", JSON.stringify(data));
      } catch (error) {
        // console.log(error);
        Swal.fire({
          type: "error",
          title: "ผิดพลาด",
          text: "เกิดข้อผิดพลาดในการดึงข้อมูล",
          confirmButtonText: "ตกลง",
        });
      }
    }
  };

  handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    // console.log('activeidx', this.state.activeIndex)

    const newIndex = this.state.activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  getUploadButton = (products, id, factoryType) => {
    let file_product_request = (
      <Button
        fluid
        positive
        content="ดูใบอนุญาต"
        icon="file alternate"
        onClick={() => this.openUploadModal(products, 1, id, factoryType)}
      />
    );

    let file_tisi_2721 = (
      <Button
        fluid
        positive
        content="ดูใบอนุญาต"
        icon="file alternate"
        onClick={() => this.openUploadModal(products, 2, id, factoryType)}
      />
    );

    products.map((product) => {
      if (product.status_uploaded_file_product_request === 0) {
        file_product_request = (
          <Button
            fluid
            primary
            content="Upload ใบอนุญาต"
            icon="upload"
            onClick={() => this.openUploadModal(products, 1, id, factoryType)}
          />
        );
      }

      if (product.status_uploaded_file_tisi_2721 === 0) {
        file_tisi_2721 = (
          <Button
            fluid
            primary
            content="Upload ใบอนุญาต"
            icon="upload"
            onClick={() => this.openUploadModal(products, 2, id, factoryType)}
          />
        );
      }

      return null;
    });

    return (
      <React.Fragment>
        <Table.Cell>{file_product_request}</Table.Cell>
        <Table.Cell>{file_tisi_2721}</Table.Cell>
      </React.Fragment>
    );
  };

  openUploadModal = (data, type, id, factoryType) => {
    this.setState({
      modalData: data,
      modalType: type,
      requestID: id,
      factoryType: factoryType,
      isModalOpen: true,
    });
  };

  closeUploadModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleClickSurvey = () => {};

  onCloseSurveyModal = () => {
    localStorage.setItem("should_show_popup", false);
    this.setState({ shouldShowPopup: false });
  };

  onClickSurvey = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLScGZydeUeuSqjG5y4Ff4QjSxPNuDyCLuV5Kyo7jp1E0V0h-Cw/viewform",
      "_blank"
    );
  };
  render() {
    // const { activeIndex } = this.state.activeIndex
    var dataFetch = this.state.data;
    // console.log('dataFetch', dataFetch)
    return (
      <div>
        <Headers />
        {/* <AssesmentModal /> */}
        {/* <SurveyModal
          onClose={this.onCloseSurveyModal}
          onClickSurvey={this.onClickSurvey}
          open={this.state.shouldShowPopup}
        /> */}
        <ModalUploadFile
          id={this.state.requestID}
          factoryType={this.state.factoryType}
          title={"TEST_HEAD_PROPS"}
          isModalOpen={this.state.isModalOpen}
          closeModal={this.closeUploadModal}
          data={this.state.modalData}
          type={this.state.modalType}
          getRequestCallback={this.getRequestData}
          currentPage={this.state.currentPage}
        />
        <div style={{ margin: 6 }}>
          <Container fluid>
            <Dimmer active={this.state.loader_all}>
              <Loader size="massive">Loading</Loader>
            </Dimmer>
            {/* <Header as="h2" textAlign="left">
              <Icon name="list layout" color="violet" />
              รายการยื่นคำขอสติ๊กเกอร์
            </Header>
            <Divider /> */}
            <Menu
              attached="top"
              icon="labeled"
              tabular
              color="violet"
              size="small"
            >
              <Menu.Item
                name="ฐานข้อมูล Eco Sticker"
                // icon="database"
                active={this.state.activeItem === "ฐานข้อมูล Eco Sticker"}
                onClick={this.handleMenuClick}
              >
                <Image
                  src={`${process.env.PUBLIC_URL}/logo-eco.png`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "45px",
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    borderTopRightRadius: 10,
                    borderTopLeftRadius: 10,
                    // marginBottom: "10px" ,
                  }}
                />
                ฐานข้อมูล ECO Sticker
              </Menu.Item>
              {/* <Menu.Item
                name="สติ๊กเกอร์ที่ถูกระงับ"
                icon="times"
                active={this.state.activeItem === "สติ๊กเกอร์ที่ถูกระงับ"}
                onClick={this.handleMenuClick}
              /> */}
              {/* <Menu.Item
                name="แก้ไข"
                icon="edit"
                active={this.state.activeItem === "แก้ไข"}
                onClick={this.handleMenuClick}
              /> */}
              <Menu.Item
                name="ฉบับร่าง"
                icon="save"
                active={this.state.activeItem === "ฉบับร่าง"}
                onClick={this.handleMenuClick}
              />
            </Menu>
            {/* ประเภทผู้ยื่นขอ ชื่อโรงงาน วันที่สร้าง วันที่อัปเดตล่าสุด */}

            <Segment
              attached="bottom"
              style={{ padding: 20 }}
              textAlign="center"
              // style={{ }}
            >
              <Dimmer active={this.state.loader_table}>
                <Loader size="massive">Loading</Loader>
              </Dimmer>

              <Grid>
                <Grid.Column>
                  {(() => {
                    switch (this.state.activeItem) {
                      case "ฉบับร่าง":
                        let button_draft = (
                          <div>
                            <Button
                              fluid
                              icon
                              labelPosition="left"
                              type="button"
                              floated="right"
                              color="violet"
                              size="large"
                              onClick={async () => {
                                await this.props.dispatch(
                                  fetchRequest(this.state.newRequest)
                                );
                                localStorage.removeItem("draftId");
                                this.props.history.push(requestLicensePath);
                              }}
                            >
                              <Icon name="plus" />
                              เพิ่มแบบร่าง
                            </Button>
                            <br /> <br />
                          </div>
                        );

                        let tableHeader_draft = (
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell collapsing>
                                ลำดับ
                              </Table.HeaderCell>
                              <Table.HeaderCell style={{ width: "20%" }}>
                                ชื่อฉบับร่าง
                              </Table.HeaderCell>

                              <Table.HeaderCell style={{ width: "30%" }}>
                                ชื่อโรงงาน
                              </Table.HeaderCell>
                              <Table.HeaderCell style={{ width: "10%" }}>
                                ประเภทโรงงาน
                              </Table.HeaderCell>
                              <Table.HeaderCell style={{ width: "15%" }}>
                                วันที่สร้าง
                              </Table.HeaderCell>
                              <Table.HeaderCell style={{ width: "15%" }}>
                                วันที่อัปเดตล่าสุด
                              </Table.HeaderCell>
                              <Table.HeaderCell style={{ width: "10%" }}>
                                Action
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                        );

                        let tableBody_draft = [];
                        let tableBody_draft_mobile = [];

                        dataFetch.forEach((data_fetch, data_index) => {
                          if (
                            data_index >=
                              this.state.rowQty *
                                (this.state.currentPage - 1) &&
                            data_index <
                              this.state.rowQty * this.state.currentPage
                          ) {
                            let rowBody_draft = (
                              <React.Fragment key={data_index}>
                                <Table.Row>
                                  <Table.Cell>
                                    {dataFetch.length - data_index}{" "}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {data_fetch.tyre_requests_name}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {data_fetch.factories_name_th}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {data_fetch.type_factories_name}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {this.formatDate(data_fetch.created_at)}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {this.formatDate(data_fetch.updated_at)}
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Button
                                      circular
                                      icon="edit"
                                      type="button"
                                      color={"yellow"}
                                      onClick={() => {
                                        localStorage.setItem(
                                          "draftId",
                                          data_fetch.id
                                        );
                                        this.props.history.push(
                                          requestLicensePath
                                        );
                                      }}
                                    >
                                      {/* <Icon name="edit" /> */}
                                    </Button>
                                    {/* </Link> */}

                                    <Button
                                      circular
                                      icon="trash"
                                      type="button"
                                      negative
                                      onClick={() => {
                                        Swal.fire({
                                          title: "ต้องการลบฉบับร่างหรือไม่?",
                                          // text: "You won't be able to revert this!",
                                          type: "warning",
                                          showCancelButton: true,
                                          confirmButtonColor: "#43A047",
                                          cancelButtonColor: "#E53935",
                                          confirmButtonText: "ลบ",
                                          cancelButtonText: "ยกเลิก",
                                        }).then(async (result) => {
                                          // console.log(data_fetch.id);
                                          if (result.value) {
                                            this.setState({ loader_all: true });
                                            await this.deleteDraft(
                                              data_fetch.id
                                            );
                                            this.setState({
                                              loader_all: false,
                                            });
                                          }
                                        });
                                      }}
                                    />
                                  </Table.Cell>
                                </Table.Row>
                              </React.Fragment>
                            );

                            tableBody_draft.push(rowBody_draft);
                          }
                        });

                        dataFetch.forEach((data_fetch, data_index) => {
                          if (
                            data_index >=
                              this.state.rowQty *
                                (this.state.currentPage - 1) &&
                            data_index <
                              this.state.rowQty * this.state.currentPage
                          ) {
                            let rowBody_draft = (
                              <React.Fragment key={data_index}>
                                <Table.Row>
                                  <Table.Cell>
                                    <span style={{ fontWeight: "bolder" }}>
                                      ลำดับ:
                                    </span>{" "}
                                    {dataFetch.length - data_index}{" "}
                                  </Table.Cell>
                                  <Table.Cell>
                                    <span style={{ fontWeight: "bolder" }}>
                                      ชื่อฉบับร่าง:
                                    </span>{" "}
                                    {data_fetch.tyre_requests_name}
                                  </Table.Cell>
                                  <Table.Cell>
                                    <span style={{ fontWeight: "bolder" }}>
                                      ชื่อโรงงาน:
                                    </span>{" "}
                                    {data_fetch.factories_name_th}
                                  </Table.Cell>
                                  <Table.Cell>
                                    <span style={{ fontWeight: "bolder" }}>
                                      ประเภทโรงงาน:
                                    </span>{" "}
                                    {data_fetch.type_factories_name}
                                  </Table.Cell>
                                  <Table.Cell>
                                    <span style={{ fontWeight: "bolder" }}>
                                      วันที่สร้าง:
                                    </span>{" "}
                                    {this.formatDate(data_fetch.created_at)}
                                  </Table.Cell>
                                  <Table.Cell>
                                    <span style={{ fontWeight: "bolder" }}>
                                      วันที่อัปเดต:
                                    </span>{" "}
                                    {this.formatDate(data_fetch.updated_at)}
                                  </Table.Cell>
                                  <Table.Cell>
                                    <span style={{ fontWeight: "bolder" }}>
                                      แก้ไข/ลบฉบับร่าง:
                                    </span>{" "}
                                    <Button
                                      circular
                                      icon="edit"
                                      type="button"
                                      color={"yellow"}
                                      onClick={() => {
                                        localStorage.setItem(
                                          "draftId",
                                          data_fetch.id
                                        );
                                        this.props.history.push(
                                          requestLicensePath
                                        );
                                      }}
                                    >
                                      {/* <Icon name="edit" /> */}
                                    </Button>
                                    {/* </Link> */}
                                    <Button
                                      circular
                                      icon="trash"
                                      type="button"
                                      negative
                                      onClick={() => {
                                        Swal.fire({
                                          title: "ต้องการลบฉบับร่างหรือไม่?",
                                          // text: "You won't be able to revert this!",
                                          type: "warning",
                                          showCancelButton: true,
                                          confirmButtonColor: "#43A047",
                                          cancelButtonColor: "#E53935",
                                          confirmButtonText: "ลบ",
                                          cancelButtonText: "ยกเลิก",
                                        }).then(async (result) => {
                                          // console.log(data_fetch.id);
                                          if (result.value) {
                                            this.setState({ loader_all: true });
                                            await this.deleteDraft(
                                              data_fetch.id
                                            );
                                            this.setState({
                                              loader_all: false,
                                            });
                                          }
                                        });
                                      }}
                                    />
                                  </Table.Cell>
                                </Table.Row>
                              </React.Fragment>
                            );

                            tableBody_draft_mobile.push(rowBody_draft);
                          }
                        });

                        let tableFooter_draft = (
                          <Table.Footer>
                            <Table.Row>
                              <Table.HeaderCell colSpan="9" textAlign="right">
                                Row
                                <Dropdown
                                  placeholder="Row"
                                  selection
                                  compact
                                  name="rowQty"
                                  value={this.state.rowQty}
                                  options={this.state.allSizeRow}
                                  onChange={this.changeRowSize}
                                  style={{
                                    marginLeft: 10,
                                    marginRight: 10,
                                    width: 60,
                                  }}
                                />
                                Page
                                <Pagination
                                  boundaryRange={1}
                                  activePage={this.state.currentPage}
                                  siblingRange={1}
                                  name="currentPage"
                                  totalPages={this.state.pageQty}
                                  onPageChange={(e, { name, activePage }) =>
                                    this.changePage(name, Math.ceil(activePage))
                                  }
                                  style={{ marginLeft: 10, marginRight: 10 }}
                                />
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Footer>
                        );

                        let tableFooter_draft_mobile = (
                          <Table.Footer>
                            <Table.Row>
                              <Table.HeaderCell colSpan="9" textAlign="right">
                                Row
                                <Dropdown
                                  placeholder="Row"
                                  selection
                                  compact
                                  name="rowQty"
                                  value={this.state.rowQty}
                                  options={this.state.allSizeRow}
                                  onChange={this.changeRowSize}
                                />
                                Page
                                <Pagination
                                  boundaryRange={0}
                                  activePage={this.state.currentPage}
                                  siblingRange={0}
                                  name="currentPage"
                                  totalPages={this.state.pageQty}
                                  onPageChange={(e, { name, activePage }) =>
                                    this.changePage(name, Math.ceil(activePage))
                                  }
                                  style={{ marginLeft: 10, marginRight: 10 }}
                                />
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Footer>
                        );

                        return (
                          <Grid>
                            <Grid.Row>
                              <Grid.Column
                                floated="right"
                                mobile={16}
                                tablet={4}
                                computer={3}
                              >
                                {button_draft}
                              </Grid.Column>
                            </Grid.Row>
                            <Grid.Row only="tablet computer">
                              <Grid.Column>
                                <Table padded celled textAlign="center">
                                  {tableHeader_draft}
                                  <Table.Body>{tableBody_draft}</Table.Body>

                                  {tableFooter_draft}
                                </Table>
                              </Grid.Column>
                            </Grid.Row>

                            <Grid.Row only="mobile">
                              <Grid.Column>
                                <Table
                                  style={{ display: "block" }}
                                  padded
                                  celled
                                >
                                  <Table.Body>
                                    {tableBody_draft_mobile}
                                  </Table.Body>

                                  {tableFooter_draft_mobile}
                                </Table>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        );

                      case "ฐานข้อมูล Eco Sticker":
                        let button_delete_request = (
                          <div>
                            <Button
                              icon
                              labelPosition="left"
                              type="button"
                              floated="right"
                              color="red"
                              size="medium"
                              onClick={async () => {
                                await this.props.dispatch(
                                  fetchRequest(this.state.newRequest)
                                );
                                localStorage.removeItem("draftId");
                                this.props.history.push(requestLicensePath);
                              }}
                            >
                              <Icon name="trash" />
                              ลบคำขอ
                            </Button>
                            <br /> <br />
                          </div>
                        );

                        let tableHeader_approve = (
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell rowSpan="2" width={1}>
                                ลำดับ
                              </Table.HeaderCell>
                              <Table.HeaderCell rowSpan="2" width={3}>
                                Reference Code
                              </Table.HeaderCell>

                              <Table.HeaderCell rowSpan="2" width={3}>
                                ชื่อโรงงาน
                              </Table.HeaderCell>
                              {/* <Table.HeaderCell style={{ width: "10%" }}>
                              ประเภทโรงงาน
                            </Table.HeaderCell>
                            <Table.HeaderCell style={{ width: "30%" }}>
                              รุ่น
                            </Table.HeaderCell> */}

                              <Table.HeaderCell rowSpan="2" width={3}>
                                วันที่ทำรายการ
                              </Table.HeaderCell>

                              <Table.HeaderCell rowSpan="2" width={1}>
                                ไฟล์ Sticker
                              </Table.HeaderCell>

                              <Table.HeaderCell colSpan="2" width={5}>
                                Upload ใบอนุญาตเพิ่มเติม
                              </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                              <Table.HeaderCell width={2}>
                                <div>มอก. 2718-2560/</div>
                                <div>มอก. 2719-2560</div>
                              </Table.HeaderCell>
                              <Table.HeaderCell width={2}>
                                มอก. 2721-2560
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                        );

                        let tableBody_approve = [];
                        let tableBody_approve_mobile = [];

                        dataFetch.forEach((data_fetch, data_index) => {
                          if (
                            data_index >=
                              this.state.rowQty *
                                (this.state.currentPage - 1) &&
                            data_index <
                              this.state.rowQty * this.state.currentPage
                          ) {
                            let rowBody_wait = (
                              <React.Fragment key={data_index}>
                                <Table.Row>
                                  <Table.Cell>
                                    {dataFetch.length - data_index}{" "}
                                  </Table.Cell>
                                  <Table.Cell>{data_fetch.ref_code}</Table.Cell>
                                  <Table.Cell>
                                    {data_fetch.factories_name}
                                  </Table.Cell>

                                  <Table.Cell>
                                    {this.formatDate(data_fetch.submit_at)}
                                  </Table.Cell>

                                  <Table.Cell>
                                    <Button
                                      size="large"
                                      circular
                                      icon="file pdf"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        localStorage.setItem(
                                          "requestId",
                                          data_fetch.id
                                        );
                                        this.props.history.push(
                                          requestPreviewPath
                                        );
                                      }}
                                    />
                                  </Table.Cell>

                                  {this.getUploadButton(
                                    data_fetch.product_requests,
                                    data_fetch.id,
                                    data_fetch.type_factories
                                  )}
                                </Table.Row>
                              </React.Fragment>
                            );

                            tableBody_approve.push(rowBody_wait);
                          }
                        });

                        dataFetch.forEach((data_fetch, data_index) => {
                          if (
                            data_index >=
                              this.state.rowQty *
                                (this.state.currentPage - 1) &&
                            data_index <
                              this.state.rowQty * this.state.currentPage
                          ) {
                            let rowBody_wait = (
                              <React.Fragment key={data_index}>
                                <Table.Row>
                                  <Table.Cell>
                                    <span style={{ fontWeight: "bolder" }}>
                                      ลำดับ:
                                    </span>{" "}
                                    {dataFetch.length - data_index}{" "}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <span style={{ fontWeight: "bolder" }}>
                                      Reference Code:
                                    </span>{" "}
                                    {data_fetch.ref_code}
                                  </Table.Cell>
                                  <Table.Cell>
                                    <span style={{ fontWeight: "bolder" }}>
                                      ชื่อโรงงาน:
                                    </span>{" "}
                                    {data_fetch.factories_name}
                                  </Table.Cell>

                                  <Table.Cell>
                                    <span style={{ fontWeight: "bolder" }}>
                                      วันที่:
                                    </span>{" "}
                                    {this.formatDate(data_fetch.submit_at)}
                                  </Table.Cell>

                                  <Table.Cell>
                                    <span style={{ fontWeight: "bolder" }}>
                                      ไฟล์ Sticker:
                                    </span>
                                    <Button
                                      size="large"
                                      circular
                                      icon="file pdf"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        localStorage.setItem(
                                          "requestId",
                                          data_fetch.id
                                        );
                                        this.props.history.push(
                                          requestPreviewPath
                                        );
                                      }}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <div style={{ fontWeight: "bolder" }}>
                                      Upload ใบอนุญาตเพิ่มเติม มอก.
                                      2718-2560/มอก. 2719-2560 และ 2721-2560
                                      ตามลำดับ
                                    </div>
                                  </Table.Cell>
                                  {this.getUploadButton(
                                    data_fetch.product_requests,
                                    data_fetch.id,
                                    data_fetch.type_factories
                                  )}
                                </Table.Row>
                              </React.Fragment>
                            );

                            tableBody_approve_mobile.push(rowBody_wait);
                          }
                        });

                        let tableFooter_approve = (
                          <Table.Footer>
                            <Table.Row>
                              <Table.HeaderCell colSpan="9" textAlign="right">
                                Row
                                <Dropdown
                                  placeholder="Row"
                                  selection
                                  compact
                                  name="rowQty"
                                  value={this.state.rowQty}
                                  options={this.state.allSizeRow}
                                  onChange={this.changeRowSize}
                                  style={{
                                    marginLeft: 10,
                                    marginRight: 10,
                                    width: 60,
                                  }}
                                />
                                Page
                                <Pagination
                                  boundaryRange={1}
                                  activePage={this.state.currentPage}
                                  siblingRange={1}
                                  name="currentPage"
                                  totalPages={this.state.pageQty}
                                  onPageChange={(e, { name, activePage }) =>
                                    this.changePage(name, Math.ceil(activePage))
                                  }
                                  style={{ marginLeft: 10, marginRight: 10 }}
                                />
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Footer>
                        );

                        let tableFooter_approve_mobile = (
                          <Table.Footer>
                            <Table.Row>
                              <Table.HeaderCell colSpan="9" textAlign="right">
                                Row
                                <Dropdown
                                  placeholder="Row"
                                  selection
                                  compact
                                  name="rowQty"
                                  value={this.state.rowQty}
                                  options={this.state.allSizeRow}
                                  onChange={this.changeRowSize}
                                  style={{
                                    marginLeft: 10,
                                    marginRight: 10,
                                    width: 60,
                                  }}
                                />
                                Page
                                <Pagination
                                  boundaryRange={0}
                                  activePage={this.state.currentPage}
                                  siblingRange={0}
                                  name="currentPage"
                                  totalPages={this.state.pageQty}
                                  onPageChange={(e, { name, activePage }) =>
                                    this.changePage(name, Math.ceil(activePage))
                                  }
                                  style={{ marginLeft: 10, marginRight: 10 }}
                                />
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Footer>
                        );

                        return (
                          <Grid>
                            <Grid.Row only="tablet computer">
                              <Grid.Column>
                                <div style={{ overflowX: "auto" }}>
                                  {/* {button_delete_request} */}
                                  <Table
                                    // style={{ display: "block" }}
                                    celled
                                    structured
                                    textAlign="center"
                                  >
                                    {tableHeader_approve}
                                    <Table.Body>{tableBody_approve}</Table.Body>
                                    {tableFooter_approve}
                                  </Table>
                                </div>
                              </Grid.Column>
                            </Grid.Row>

                            <Grid.Row only="mobile">
                              <Grid.Column>
                                <div style={{ overflowX: "auto" }}>
                                  {/* {button_delete_request} */}
                                  <Table
                                    style={{ display: "block" }}
                                    celled
                                    structured

                                    // textAlign="center"
                                  >
                                    <Table.Body>
                                      {tableBody_approve_mobile}
                                    </Table.Body>
                                    {tableFooter_approve_mobile}
                                  </Table>
                                </div>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        );

                      case "สติ๊กเกอร์ที่ถูกระงับ":
                        let tableHeader_not_approve = (
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell collapsing>
                                ลำดับ
                              </Table.HeaderCell>
                              <Table.HeaderCell style={{ width: "15%" }}>
                                Ref Code
                              </Table.HeaderCell>

                              <Table.HeaderCell style={{ width: "25%" }}>
                                ชื่อโรงงาน
                              </Table.HeaderCell>
                              <Table.HeaderCell style={{ width: "10%" }}>
                                ประเภทโรงงาน
                              </Table.HeaderCell>
                              <Table.HeaderCell style={{ width: "30%" }}>
                                รุ่น
                              </Table.HeaderCell>

                              <Table.HeaderCell style={{ width: "20%" }}>
                                วันที่ถูกระงับ
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                        );

                        let tableBody_not_approve = [];

                        dataFetch.forEach((data_fetch, data_index) => {
                          if (
                            data_index >=
                              this.state.rowQty *
                                (this.state.currentPage - 1) &&
                            data_index <
                              this.state.rowQty * this.state.currentPage
                          ) {
                            let rowBody_wait = (
                              <React.Fragment key={data_index}>
                                <Table.Row>
                                  <Table.Cell>
                                    {dataFetch.length - data_index}{" "}
                                  </Table.Cell>
                                  <Table.Cell>
                                    <a
                                      href=""
                                      onClick={(e) => {
                                        e.preventDefault();
                                        localStorage.setItem(
                                          "requestId",
                                          data_fetch.id
                                        );
                                        this.props.history.push(
                                          requestPreviewPath
                                        );
                                        // alert(data_fetch.id);
                                        // this.props.history.push(
                                        //   requestLicensePath
                                        // );
                                      }}
                                    >
                                      {data_fetch.ref_code}
                                    </a>
                                  </Table.Cell>
                                  <Table.Cell>
                                    {data_fetch.factories_name}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {data_fetch.type_factories}
                                  </Table.Cell>
                                  <Table.Cell textAlign={"left"}>
                                    <Accordion>
                                      <Accordion.Title
                                        active={
                                          this.state.activeIndex === data_index
                                        }
                                        index={data_index}
                                        onClick={this.handleAccordionClick}
                                      >
                                        <Icon name="dropdown" />
                                        รายการรุ่น
                                      </Accordion.Title>
                                      <Accordion.Content
                                        active={
                                          this.state.activeIndex === data_index
                                        }
                                      >
                                        {data_fetch.gen_requests.map((gen) => (
                                          <li>{gen.gen_name}</li>
                                        ))}
                                      </Accordion.Content>
                                    </Accordion>
                                  </Table.Cell>

                                  <Table.Cell>
                                    {this.formatDate(data_fetch.submit_at)}
                                  </Table.Cell>
                                </Table.Row>
                              </React.Fragment>
                            );

                            tableBody_not_approve.push(rowBody_wait);
                          }
                        });

                        let tableFooter_not_approve = (
                          <Table.Footer>
                            <Table.Row>
                              <Table.HeaderCell colSpan="9" textAlign="right">
                                Row
                                <Dropdown
                                  placeholder="Row"
                                  selection
                                  compact
                                  name="rowQty"
                                  value={this.state.rowQty}
                                  options={this.state.allSizeRow}
                                  onChange={this.changeRowSize}
                                  style={{
                                    marginLeft: 10,
                                    marginRight: 10,
                                    width: 60,
                                  }}
                                />
                                Page
                                <Pagination
                                  boundaryRange={1}
                                  activePage={this.state.currentPage}
                                  siblingRange={1}
                                  name="currentPage"
                                  totalPages={this.state.pageQty}
                                  onPageChange={(e, { name, activePage }) =>
                                    this.changePage(name, Math.ceil(activePage))
                                  }
                                  style={{ marginLeft: 10, marginRight: 10 }}
                                />
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Footer>
                        );

                        return (
                          <div>
                            <Table padded celled textAlign="center">
                              {tableHeader_not_approve}
                              <Table.Body>{tableBody_not_approve}</Table.Body>
                              {tableFooter_not_approve}
                            </Table>
                          </div>
                        );

                      case "แก้ไข":
                        let tableHeader_edit = (
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell collapsing>
                                ลำดับ
                              </Table.HeaderCell>
                              <Table.HeaderCell style={{ width: "20%" }}>
                                Ref Code
                              </Table.HeaderCell>

                              <Table.HeaderCell style={{ width: "30%" }}>
                                ชื่อโรงงาน
                              </Table.HeaderCell>
                              <Table.HeaderCell style={{ width: "20%" }}>
                                ประเภทโรงงาน
                              </Table.HeaderCell>
                              <Table.HeaderCell style={{ width: "20%" }}>
                                วันที่ยื่นขอ
                              </Table.HeaderCell>

                              <Table.HeaderCell style={{ width: "10%" }}>
                                Action
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                        );

                        let tableBody_edit = [];

                        dataFetch.forEach((data_fetch, data_index) => {
                          if (
                            data_index >=
                              this.state.rowQty *
                                (this.state.currentPage - 1) &&
                            data_index <
                              this.state.rowQty * this.state.currentPage
                          ) {
                            let rowBody_wait = (
                              <React.Fragment key={data_index}>
                                <Table.Row>
                                  <Table.Cell>
                                    {dataFetch.length - data_index}{" "}
                                  </Table.Cell>
                                  <Table.Cell>{data_fetch.ref_code}</Table.Cell>
                                  <Table.Cell>
                                    {data_fetch.factories_name}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {data_fetch.type_factories}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {this.formatDate(data_fetch.submit_at)}
                                  </Table.Cell>

                                  <Table.Cell>
                                    <Button
                                      // circular
                                      icon="search"
                                      type="button"
                                      color={"green"}
                                      onClick={() => {
                                        localStorage.setItem(
                                          "requestId",
                                          data_fetch.id
                                        );
                                        this.props.history.push(
                                          requestPreviewPath
                                        );
                                        // alert(data_fetch.id);
                                        // this.props.history.push(
                                        //   requestLicensePath
                                        // );
                                      }}
                                    />
                                  </Table.Cell>
                                </Table.Row>
                              </React.Fragment>
                            );

                            tableBody_edit.push(rowBody_wait);
                          }
                        });

                        let tableFooter_edit = (
                          <Table.Footer>
                            <Table.Row>
                              <Table.HeaderCell colSpan="9" textAlign="right">
                                Row
                                <Dropdown
                                  placeholder="Row"
                                  selection
                                  compact
                                  name="rowQty"
                                  value={this.state.rowQty}
                                  options={this.state.allSizeRow}
                                  onChange={this.changeRowSize}
                                  style={{
                                    marginLeft: 10,
                                    marginRight: 10,
                                    width: 60,
                                  }}
                                />
                                Page
                                <Pagination
                                  boundaryRange={1}
                                  activePage={this.state.currentPage}
                                  siblingRange={1}
                                  name="currentPage"
                                  totalPages={this.state.pageQty}
                                  onPageChange={(e, { name, activePage }) =>
                                    this.changePage(name, Math.ceil(activePage))
                                  }
                                  style={{ marginLeft: 10, marginRight: 10 }}
                                />
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Footer>
                        );

                        return (
                          <div>
                            <Table padded celled textAlign="center">
                              {tableHeader_edit}
                              <Table.Body>{tableBody_edit}</Table.Body>
                              {tableFooter_edit}
                            </Table>
                          </div>
                        );

                      default:
                        break;
                    }
                  })()}
                </Grid.Column>
              </Grid>
              {/* {this.state.activeItem === "ฉบับร่าง" ? (
                <div>
                  <Button
                    icon
                    labelPosition="left"
                    type="button"
                    floated="right"
                    color="violet"
                    size="large"
                    onClick={async () => {
                      await this.props.dispatch(
                        fetchRequest(this.state.newRequest)
                      );
                      localStorage.removeItem("draftId");
                      this.props.history.push(requestLicensePath);
                    }}
                  >
                    <Icon name="plus" />
                    เพิ่มแบบร่าง
                  </Button>
                  <br /> <br />
                </div>
              ) : (
                <span />
              )} */}
            </Segment>
          </Container>
        </div>
      </div>
    );
  }

  deleteDraft = async (draft_id) => {
    try {
      let { data } = await axios.delete(DELETE_DRAFT_DATA_BY_ID + draft_id, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      // console.log(data);

      if (data.message === "success") {
        try {
          let { data } = await axios.get(
            GET_DRAFT_ALL_DATA_COMPANY,
            TOKEN_COMPANY()
          );

          // console.log("after delete", data);

          if (data.value !== null) {
            await this.setState(
              {
                data: data.value,
                loader_table: false,
              },
              async () => {
                let lengthData = 1;
                if (this.state.data !== null) {
                  lengthData = this.state.data.length;
                }
                let pageQty = lengthData / this.state.rowQty;
                await this.setState({
                  pageQty: Math.ceil(pageQty),
                  currentPage: 1,
                });
              }
            );
          } else {
            await this.setState({
              loader_table: false,
              data: [],
            });
          }
        } catch (error) {
          // console.log(error);
          Swal.fire({
            type: "error",
            title: "ผิดพลาด",
            text: "เกิดข้อผิดพลาดในการดึงข้อมูล",
            confirmButtonText: "ตกลง",
          });
        }
      }
    } catch (error) {
      // console.log(error);
      Swal.fire({
        type: "error",
        title: "ผิดพลาด",
        text: "เกิดข้อผิดพลาดในการลบข้อมูล",
        confirmButtonText: "ตกลง",
      });
    }

    Swal.fire({
      title: "ลบ!",
      type: "success",
      confirmButtonColor: "#03A9F4",
      confirmButtonText: "ตกลง",
    });
  };

  deleteRequest = async (request_id) => {
    try {
      let { status } = await axios.delete(
        DELETE_REQUEST_DATA_BY_ID + request_id,
        TOKEN_COMPANY()
      );

      if (status === 200) {
        Swal.fire({
          title: "ลบ!",
          type: "success",
          confirmButtonColor: "#03A9F4",
          confirmButtonText: "ตกลง",
        });

        try {
          let { data } = await axios.get(
            GET_REQUEST_WAITING_FOR_APPROVAL_DATA_COMPANY,
            TOKEN_COMPANY()
          );

          // console.log("รอการอนุมัติ", data);

          if (data.value !== null) {
            await this.setState(
              { data: data.value, loader_table: false },
              async () => {
                let lengthData = 1;
                if (this.state.data !== null) {
                  lengthData = this.state.data.length;
                }
                let pageQty = lengthData / this.state.rowQty;
                await this.setState({
                  pageQty: Math.ceil(pageQty),
                  currentPage: 1,
                });
              }
            );
          } else {
            await this.setState({ loader_table: false });
          }
        } catch (error) {
          // console.log(error);
          Swal.fire({
            type: "error",
            title: "ผิดพลาด",
            text: "เกิดข้อผิดพลาดในการดึงข้อมูล",
            confirmButtonText: "ตกลง",
          });
        }
      }
    } catch (error) {
      console.log(error);
      console.log(error.response);
      let errorText = "";
      switch (error.response.status) {
        case 404:
          errorText = "ไม่พบข้อมูลคำขอ";
          break;
        case 503:
          errorText = "ไม่สามารถเชื่อมต่อฐานข้อมูล";
          break;
        case 500:
          errorText = "ระบบขัดข้อง";
          break;
        default:
          break;
      }
      // console.log(error);

      Swal.fire({
        type: "error",
        title: "ผิดพลาด",
        text: errorText,
        confirmButtonText: "ตกลง",
      });
    }
  };

  changePage = (name, value) => this.setState({ [name]: value });

  changeRowSize = (e, { name, value }) => {
    this.setState({ [name]: value, currentPage: 1 }, () => {
      let lengthData = this.state.data.length; // data length
      let pageQty = lengthData / this.state.rowQty; // data length หาร dropdown value
      this.setState({ pageQty: Math.ceil(pageQty) });
    });
  };

  handleMenuClick = async (e, { name, value }) => {
    this.setState({ activeItem: name, data: [] });
    if (name === "ฐานข้อมูล Eco Sticker") {
      // console.log(name);
      await this.setState({
        loader_table: true,
      });

      try {
        let { data } = await axios.get(
          GET_REQUEST_APPROVE_DATA_COMPANY,
          TOKEN_COMPANY()
        );

        // console.log(data);

        if (data.value !== null) {
          await this.setState(
            { data: data.value, loader_table: false },
            async () => {
              let lengthData = 1;
              if (this.state.data !== null) {
                lengthData = this.state.data.length;
              }
              let pageQty = lengthData / this.state.rowQty;
              await this.setState({
                pageQty: Math.ceil(pageQty),
                currentPage: 1,
              });
            }
          );
        } else {
          await this.setState({ loader_table: false });
        }
      } catch (error) {
        // console.log(error);
        Swal.fire({
          type: "error",
          title: "ผิดพลาด",
          text: "เกิดข้อผิดพลาดในการดึงข้อมูล",
          confirmButtonText: "ตกลง",
        });
      }

      await this.setState({
        loader_table: false,
      });
    } else if (name === "สติ๊กเกอร์ที่ถูกระงับ") {
      // console.log(name);
      await this.setState({
        loader_table: true,
      });

      try {
        let { data } = await axios.get(
          GET_REQUEST_NOT_ALLOWED_DATA_COMPANY,
          TOKEN_COMPANY()
        );

        // console.log(data);

        if (data.value !== null) {
          await this.setState(
            { data: data.value, loader_table: false },
            async () => {
              let lengthData = 1;
              if (this.state.data !== null) {
                lengthData = this.state.data.length;
              }
              let pageQty = lengthData / this.state.rowQty;
              await this.setState({
                pageQty: Math.ceil(pageQty),
                currentPage: 1,
              });
            }
          );
        } else {
          await this.setState({ loader_table: false });
        }
      } catch (error) {
        // console.log(error);
        Swal.fire({
          type: "error",
          title: "ผิดพลาด",
          text: "เกิดข้อผิดพลาดในการดึงข้อมูล",
          confirmButtonText: "ตกลง",
        });
      }

      await this.setState({
        loader_table: false,
      });
    } else if (name === "แก้ไข") {
      // console.log(name);
      await this.setState({
        loader_table: true,
      });

      try {
        let { data } = await axios.get(
          GET_REQUEST_EDIT_COMPANY,
          TOKEN_COMPANY()
        );

        // console.log(data);

        if (data.value !== null) {
          await this.setState(
            { data: data.value, loader_table: false },
            async () => {
              let lengthData = 1;
              if (this.state.data !== null) {
                lengthData = this.state.data.length;
              }
              let pageQty = lengthData / this.state.rowQty;
              await this.setState({
                pageQty: Math.ceil(pageQty),
                currentPage: 1,
              });
            }
          );
        } else {
          await this.setState({ loader_table: false });
        }
      } catch (error) {
        // console.log(error);
        Swal.fire({
          type: "error",
          title: "ผิดพลาด",
          text: "เกิดข้อผิดพลาดในการดึงข้อมูล",
          confirmButtonText: "ตกลง",
        });
      }

      await this.setState({
        loader_table: false,
      });
    } else if (name === "ฉบับร่าง") {
      await this.setState({
        loader_table: true,
      });

      try {
        let { data } = await axios.get(
          GET_DRAFT_ALL_DATA_COMPANY,
          TOKEN_COMPANY()
        );

        // console.log(data);

        if (data.value !== null) {
          await this.setState(
            { data: data.value, loader_table: false },
            async () => {
              let lengthData = 1;
              if (this.state.data !== null) {
                lengthData = this.state.data.length;
              }
              let pageQty = lengthData / this.state.rowQty;
              await this.setState({
                pageQty: Math.ceil(pageQty),
                currentPage: 1,
              });
            }
          );
        } else {
          await this.setState({ loader_table: false });
        }
      } catch (error) {
        // console.log(error);
        Swal.fire({
          type: "error",
          title: "ผิดพลาด",
          text: "เกิดข้อผิดพลาดในการดึงข้อมูล",
          confirmButtonText: "ตกลง",
        });
      }

      await this.setState({
        loader_table: false,
      });
    }
  };

  formatDate = (dateString) => {
    var date = new Date(dateString);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();

    if (day < 10) {
      day = "0" + day;
    }

    switch (month) {
      case 1:
        month = "Jan";
        break;
      case 2:
        month = "Feb";
        break;
      case 3:
        month = "Mar";
        break;
      case 4:
        month = "Apr";
        break;
      case 5:
        month = "May";
        break;
      case 6:
        month = "Jun";
        break;
      case 7:
        month = "Jul";
        break;
      case 8:
        month = "Aug";
        break;
      case 9:
        month = "Sep";
        break;
      case 10:
        month = "Oct";
        break;
      case 11:
        month = "Nov";
        break;
      case 12:
        month = "Dec";
        break;
      default:
        break;
    }

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    var formattedDate =
      " วันที่ " +
      day +
      "-" +
      month +
      "-" +
      (year + 543) +
      "\n เวลา " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds;

    return formattedDate;
  };
}

const mapStateToProps = function (state) {
  return {
    requestRedux: state.requestRedux,
  };
};

const SurveyModal = (props) => {
  return (
    <Modal
      size={"small"}
      centered={false}
      {...props}
      // onClose={() => setOpen(false)}
      // onOpen={() => setOpen(true)}
      // trigger={<Button>Show Modal</Button>}
    >
      <Modal.Header>แจ้งจากระบบ</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          กรุณาตอบแบบสำรวจความพึงพอใจต่อการใช้ระบบ ECO Sticker รถยนต์ที่ปรับปรุง
          เพื่อใช้ในการพัฒนาระบบ
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="green"
          onClick={() => {
            props.onClickSurvey();
            props.onClose();
          }}
        >
          กรอกแบบสำรวจความพึงพอใจ
        </Button>
        <Button color="red" onClick={() => props.onClose()}>
          ปิด
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default connect(mapStateToProps)(requestIndex);
