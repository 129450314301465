import React, { useState, useRef } from "react";
import { Grid, GridColumn, Icon, Input, Accordion } from "semantic-ui-react";
import { BoxListSelected, ButtonStyled, BoxContent, BoxSuggestionsContent, ImageContainer, StyledImage } from "./StyledComponent";
import EmptyBox from "../../../assets/icon/box.png";
import styled from "styled-components";

const ListBandComponent = ({ data = [], fnUpdateListSuggestion, fnCreateListSuggestion, fnDeleteListSuggestion }) => {
	const [search_text, setsearchText] = useState("");
	const [activeIndex, setActiveIndex] = useState(null);
	const [suggestion_text, setsuggestionText] = useState("");
	const [open_add_suggestion_form, setOpenAddSuggestionForm] = useState(false);
	const [add_suggestion_text, setAddSuggestionText] = useState("");

	const handleClick = (val, index) => {
		setActiveIndex(activeIndex === index ? null : index);
		setsuggestionText(val.name);
	};

	const handleOnChange = (e) => {
		setsearchText(e.target.value);
	};

	const handleOnChangeEdit = (e) => {
		setsuggestionText(e.target.value);
	};

	const filteredData = data.filter((item) => item.name.toLowerCase().includes(search_text.toLowerCase()));

	const handleSubmitCreateListSuggestion = (val) => {
		fnCreateListSuggestion(val);
		setsearchText("");
        fnOpenCreateSuggestionForm()
	};

	const handleEditSuggestion = (val) => {
		fnUpdateListSuggestion(val);
		setsearchText("");
	};

	const handleDeleteSuggestion = (val) => {
		fnDeleteListSuggestion(val);
		setActiveIndex(null);
	};

	const fnOpenCreateSuggestionForm = () => {
		setOpenAddSuggestionForm(!open_add_suggestion_form);
		setAddSuggestionText("");
	};

	return (
		<BoxContent>
			<div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
				<div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
					<span>รายการคำ Suggestions</span>
				</div>
				<div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", marginTop: "10px" }}>
					<div style={{ width: "100%" }}>
						<Input
							icon={{ name: "search", circular: true, link: true }}
							placeholder="ค้นหาชื่อแบรนด์"
							fluid
							size="small"
							onChange={handleOnChange}
						/>
					</div>
					<ButtonStyled color="success" onClick={() => fnOpenCreateSuggestionForm()}>เพิ่ม</ButtonStyled>
				</div>
				{open_add_suggestion_form && (
					<div style={{ padding: "15px", border: "1px solid #f0f0f0", marginTop: "10px", borderRadius: "5px" }}>
						<div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
							<span>เพิ่มคำ Suggestion</span>
						</div>
						<Input
							placeholder="คำ Suggestion"
							fluid
							size="small"
							onChange={(e) => setAddSuggestionText(e.target.value)}
						/>
						<div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
							<ButtonStyled  color="success" onClick={() => handleSubmitCreateListSuggestion(add_suggestion_text)}>บันทึก</ButtonStyled>
							<ButtonStyled onClick={() => fnOpenCreateSuggestionForm()}>ยกเลิก</ButtonStyled>
						</div>
					</div>
				)}

				<BoxListSelected>
					<div style={{ width: "100%" }}>
						{filteredData?.length > 0 ? (
							<Grid columns={1}>
								{filteredData?.map((item, index) => (
									<GridColumn style={{ padding: "5px" }}>
										<Accordion
											style={{ border: "none" }}
											styled
										>
											<StyledAccordionTitle
												active={activeIndex === index}
												onClick={() => handleClick(item, index)}
											>
												<span>{item.name}</span>
												<Icon name={activeIndex === index ? "angle up" : "angle down"} />
											</StyledAccordionTitle>
											<Accordion.Content
												style={{ padding: 0 }}
												active={activeIndex === index}
											>
												<StyledAccordionContent>
													<div style={{ display: "flex", flexDirection: "column" }}>
														<span style={{ marginBottom: "10px" }}>แก้ไขแบรนด์</span>
														<Input
															value={suggestion_text}
															size="mini"
															onChange={handleOnChangeEdit}
														/>
														<div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
															<ButtonStyled
                                                                color={item.name !== suggestion_text && "warning"}
																disabled={item.name === suggestion_text}
																onClick={() => handleEditSuggestion({ name: suggestion_text, id: item.id })}
															>
																แก้ไข
															</ButtonStyled>
															<ButtonStyled color="error" onClick={() => handleDeleteSuggestion(item.id)}>ลบ</ButtonStyled>
														</div>
													</div>
												</StyledAccordionContent>
											</Accordion.Content>
										</Accordion>
										{/* <BoxSuggestionsContent>
											{item.name}
											<Icon
												style={{ marginLeft: "5px" }}
												link
												name="x icon"
											/>
										</BoxSuggestionsContent> */}
									</GridColumn>
								))}
							</Grid>
						) : (
							<div
								style={{
									width: "100%",
									height: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									color: "#b9b9b9",
									userSelect: "none",
								}}
							>
								<ImageContainer>
									<StyledImage src={EmptyBox} />
								</ImageContainer>
								<span>ไม่พบข้อมูล</span>
							</div>
						)}
					</div>
				</BoxListSelected>
			</div>
		</BoxContent>
	);
};

export default ListBandComponent;

const StyledAccordionTitle = styled(Accordion.Title)`
	padding: 10px;
	border-radius: 10px;
	background-color: ${(props) => (props.active ? "#fff" : "#fafafa")};
	color: ${(props) => (props.active ? "#000" : "#333")}; /* Set font color */
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	transition: background-color 0.3s ease, color 0.3s ease;
`;

const StyledAccordionContent = styled(Accordion.Content)`
	/* padding: 10px; */
	background-color: #fff;
	color: #000;
	border-radius: 0 0 10px 10px;
`;

// const AccordionContainer = styled.div`
// 	display: flex;
// 	flex-direction: column;
// 	background-color: ${({ isOpen }) => (isOpen ? "#fff" : "#fafafa")};
// 	padding: 10px;
// 	border-radius: 10px;
// 	width: 100%;
// 	font-weight: normal;
// `;

// const AccordionHeader = styled.div`
// 	display: flex;
// 	justify-content: space-between;
// 	align-items: center;
// 	cursor: pointer;
// 	font-weight: bold;
// 	background-color: ${({ isOpen }) => (isOpen ? "#fff" : "#fafafa")};
// `;

// const AccordionContent = styled.div`
// 	margin-top: ${({ isOpen }) => (isOpen ? "10px" : "0")};
// 	max-height: ${({ isOpen, contentHeight }) => (isOpen ? `${contentHeight}px` : "0")};
// 	overflow: hidden;
// 	transition: max-height 0.3s ease-in-out;
// 	padding: ${({ isOpen }) => (isOpen ? "5px" : "0 5px")};
// 	background-color: #fff;
// 	transition: padding 0.3s ease-in-out;
// `;
