import React, { Component } from "react";
import { Link } from "react-router-dom";
import styles from "../../css/style_sidebar.module.scss";
import {
  Button,
  Menu,
  Image,
  Dropdown,
  Divider,
  Icon,
  Modal,
  Input,
  Form,
  Grid,
  Message,
  Label,
  Segment,
  Sidebar,
} from "semantic-ui-react";
// import headerLogo from './../../assets/img/logo-eco.png';
import axios from "axios";
import {
  CHANGE_PASSWORD,
  CHECK_STATUS_TEMP,
  UPLOAD_COMPANY_LOGO_WEBSITE,
  HEADER_FORMDATA,
  TOKEN_COMPANY,
  CHECK_PERMISSION_CHANGE_PASSWORD,
} from "../router";
import Swal from "sweetalert2";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { setDefaultStore } from "../actions/actions";
import { to } from "../handle";
import "../../css/customStyles.css"

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSidebarOpen: false,
      activeAdminMenuItem: "main",
      modalChangePassword: false,
      modalUploadlogo: false,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      messageError: false,
      messageText: "",
      loading: false,
      login: true,
      edit: false,
      editPending: false,
      URLEdit: `/company/editEntrepreneur/entrepreneur/${localStorage.getItem(
        "company_id"
      )}`,
      // upload logo and website company
      status_company_logo: "dont_insert", // dont_insert, dont_change, have_file, delete_file
      file_company_logo: { name: "", data: null }, //ไม่ตรวจสอบ
      file_company_logo_error: false,
      company_website: "",
      company_website_error: false,
      confirmButtonLogoWebsite: false,
      can_update_password:false
      // ---
    };
  }

  handleEditClick = () => {
    this.props.dispatch(setDefaultStore());
  };

  componentDidMount = () => {
    this.checkPermissionChangePassword();
  };


  componentWillMount = () => {
    if (
      localStorage.getItem("role") !== "0" ||
      localStorage.getItem("role") === null
    ) {
      this.setState({ login: false });
    }
    if (window.location.pathname.includes("request-")) {
      this.setState({
        activeAdminMenuItem: "request",
      });
    }

    if (window.location.pathname.includes("request-payment")) {
      this.setState({
        activeAdminMenuItem: "payment",
      });
    }
    axios
      .post(`${CHECK_STATUS_TEMP}${localStorage.getItem("company_id")}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.data.status === "Success") {
          this.setState({
            URLEdit: `/company/editEntrepreneur/entrepreneur/${localStorage.getItem(
              "company_id"
            )}`,
          });
        }
      })
      .catch((error) => {
        if (error.response.data.message === "Waiting for authorization") {
          this.setState({ URLEdit: `/company/editEntrepreneur/editPending` });
        } else {
          this.setState({ URLEdit: `/company/editEntrepreneur/editFailed` });
        }
      });
  };

  handleAdminMenuItemClick = (e, { name }) => {
    this.setState({ activeAdminMenuItem: name });
  };

  closeModal = () => {
    this.setState({ modalChangePassword: false });
  };

  checkPermissionChangePassword = async() =>{
    await axios.get(CHECK_PERMISSION_CHANGE_PASSWORD, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((response) => {
       this.setState({
         can_update_password: response.data.value.can_update_password
       })
    }).catch((error) => {
      console.log(error)
    });
  }

  checkPassword = () => {
    this.setState({ loading: true });

    if (
      this.state.currentPassword !== "" &&
      this.state.newPassword !== "" &&
      this.state.confirmPassword !== ""
    ) {
      if (this.state.confirmPassword === this.state.newPassword) {
        // TODO: axios change password
        axios
          .put(
            CHANGE_PASSWORD,
            {
              old_password: this.state.currentPassword,
              new_password: this.state.newPassword,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then(() => {
            this.setState(
              {
                loading: false,
                modalChangePassword: false,
                newPassword: "",
                currentPassword: "",
                confirmPassword: "",
              },
              () => {
                Swal.fire({
                  position: "center",
                  type: "success",
                  title: "เปลี่ยนรหัสผ่านสำเร็จ",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            );
          })
          .catch((error) => {
            if (error.response !== undefined) {
              if (error.response.data.message === "Old password incorrect") {
                this.setState({
                  messageError: true,
                  messageText: "รหัสผ่านเดิมไม่ถูกต้อง",
                  newPassword: "",
                  currentPassword: "",
                  confirmPassword: "",
                  loading: false,
                });
              }
            } else {
              this.setState({
                messageError: true,
                messageText: "ไม่สามารถเชื่อมต่อระบบได้",
                newPassword: "",
                currentPassword: "",
                confirmPassword: "",
                loading: false,
              });
            }
          });
      } else {
        this.setState({
          messageError: true,
          messageText: "รหัสผ่านไม่ตรงกัน",
          newPassword: "",
          currentPassword: "",
          confirmPassword: "",
          loading: false,
        });
      }
    } else {
      this.setState({
        messageError: true,
        messageText: "โปรดกรอกข้อมูลให้ครบถ้วน",
        newPassword: "",
        currentPassword: "",
        confirmPassword: "",
        loading: false,
      });
    }
  };

  handleOnChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleClickLogo = () => {
    localStorage.clear();
    this.props.history.push("/");
  };

  // function upload file
  async handleUploadFileChange(e) {
    console.log(e.target.files);
    if (e.target.files[0] !== undefined) {
      if (
        e.target.files[0].type === "image/jpeg" ||
        e.target.files[0].type === "image/png"
      ) {
        if (e.target.files[0]) {
          if (e.target.files[0].size > 10000000) {
            Swal.fire({
              type: "error",
              title: `ขนาดไฟล์ใหญ่เกินไป`,
              text: `ขนาดไฟล์ต้องไม่เกิน 10 MB`,
            });
            return;
          }
          var id = e.target.id;

          await this.setState(
            {
              status_company_logo: "have_file",
              [id]: { name: e.target.files[0].name, data: e.target.files[0] },
            },
            () => console.log(this.state)
          );

          if (id === "file_company_logo") {
            this.setState({ file_company_logo_error: false });
          }
        }
      } else {
        Swal.fire({
          type: "error",
          title: `ชนิดไฟล์ไม่ถูกต้อง กรุณาตรวจสอบใหม่อีกครั้ง`,
        });
      }
    }
  }
  async handleUploadFileReset(e) {
    if (e.target.id === "file_company_logo_reset") {
      await this.setState({
        status_company_logo: "delete_file",
        file_company_logo: { name: "", data: null },
        file_company_logo_error: true,
      });
      document.getElementById("file_company_logo").value = null;
    }
  }
  validateFuncTest = (data, type) => {
    let validate = false;
    switch (type) {
      case "string":
        if (data !== "") {
          validate = true;
        }
        break;
      case "file":
        if (data !== null) {
          validate = true;
        }
        break;

      default:
        break;
    }
    return [validate];
  };
  submitCompanyLogoWebsite = async () => {
    var state = this.state;

    // ** Comment: sub component for validate data **
    // let [validate_company_website] = this.validateFuncTest(state.company_website, 'string');
    // let [validate_file_company_logo] = this.validateFuncTest(state.file_company_logo.data, 'file');
    // if (!validate_company_website || !validate_file_company_logo) {
    // 	await this.setState({
    // 		file_company_logo_error: !validate_file_company_logo,
    // 		company_website_error: !validate_company_website
    // 	});
    // 	return
    // }

    Swal.fire({
      title: "ยืนยันการบันทึก?",
      icon: "question",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `บันทึก`,
      cancelButtonText: `ยกเลิก`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        // Swal.fire('Saved!', '', 'success')
        var formData = new FormData();
        formData.append("status_company_logo", state.status_company_logo);
        formData.append("website", state.company_website);
        formData.append("company_logo", state.file_company_logo.data);
        let result, error;
        ({ result, error } = await to(
          axios.put(UPLOAD_COMPANY_LOGO_WEBSITE, formData, HEADER_FORMDATA())
        ));
        if (error) {
          console.log(error.response);
          if (!error.response) {
            // network error
            Swal.fire({
              title:
                "ขอภัย เกิดข้อผิดพลาดจากระบบเคลือข่าย กรุณาทำรายการใหม่ในภายหลัง",
              type: "error",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "ยืนยัน",
              cancelButtonText: "ยกเลิก",
              showCancelButton: false,
              showConfirmButton: false,
              timer: 3000,
            });
            await this.setState({
              modalUploadlogo: false,
              // company_website_error: false,
              // file_company_logo_error: false,
            });
            return;
          } else {
            // response data
            Swal.fire({
              title: "ขอภัย เกิดข้อผิดพลาดในการอัพโหลด",
              type: "error",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "ยืนยัน",
              cancelButtonText: "ยกเลิก",
              showCancelButton: false,
              showConfirmButton: false,
              timer: 3000,
            });
            await this.setState({
              modalUploadlogo: false,
              // company_website_error: false,
              // file_company_logo_error: false,
            });
            return;
          }
        }
        if (result) {
          console.log(result);
          if (result.data.status === "Success") {
            // response data
            Swal.fire({
              title: "อัพเดทข้อมูลสำเร็จ",
              type: "success",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "ยืนยัน",
              cancelButtonText: "ยกเลิก",
              showCancelButton: false,
              showConfirmButton: false,
              timer: 3000,
            });
            await this.setState({
              modalUploadlogo: false,
              company_website_error: false,
              file_company_logo_error: false,
            });

            return;
          }
        }
      }
    });
  };

  openModalCompanylogoWebsite = async () => {
    let result, error;
    ({ result, error } = await to(
      axios.get(UPLOAD_COMPANY_LOGO_WEBSITE, TOKEN_COMPANY())
    ));
    if (error) {
      if (!error.response) {
        Swal.fire({
          title: "ขอภัยเกิดข้อผิดพลาดจากระบบเครือข่าย กรุณาลองใหม่ในภายหลัง",
          type: "error",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          showCancelButton: false,
          showConfirmButton: false,
          timer: 2000,
        });
        await this.setState({
          modalUploadlogo: false,
          company_website_error: false,
          file_company_logo_error: false,
        });

        return;
      } else {
        // let response = error.response.data;
        Swal.fire({
          title:
            "ขอภัยเกิดข้อผิดพลาดในการดึงข้อมูลจากระบบ กรุณาลองไหม่ในภายหลัง",
          type: "error",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          showCancelButton: false,
          showConfirmButton: false,
          timer: 2000,
        });
        await this.setState({
          modalUploadlogo: false,
          company_website_error: false,
          file_company_logo_error: false,
        });

        return;
      }
    }
    if (result) {
      let { data } = result;
      let { value } = data;
      let status_company_logo = "";
      if (value.logo_file_path != "") {
        status_company_logo = "dont_change";
      } else {
        status_company_logo = "dont_insert";
      }
      await this.setState({
        status_company_logo: status_company_logo,
        file_company_logo: {
          name: value.logo_file_name,
          data: null,
        },
        company_website: value.website,
      });
    }
    await this.setState({
      modalUploadlogo: true,
      company_website_error: false,
      file_company_logo_error: false,
    });
  };

  render() {
    if (!this.state.login) {
      // window.location.href = '/';
      return <Redirect to="/" />;
    }
    return (
      <React.Fragment>
        <Sidebar.Pushable
          className={`${styles.sidebar_pusher} ${
            this.state.isSidebarOpen ? styles.sidebar_visible : ""
          }`}
          as={Segment}
          // style={{
          //   display: this.state.isSidebarOpen ? "unset" : "none",
          // }}
        >
          <Sidebar
            as={Menu}
            animation="overlay"
            icon="labeled"
            // inverted
            onHide={() => {
              this.setState({ isSidebarOpen: false });
            }}
            vertical
            visible={this.state.isSidebarOpen}
            width="thin"
            style={{ width: "100%" }}
          >
            
            <Link to="/company/main">
              <Menu.Item
                name="request"
                target="_self"
                onClick={this.handleAdminMenuItemClick}
              >
                <Icon name="home" />
                หน้าแรก
              </Menu.Item>
            </Link>
              
            <Link to="/company/request-license">
              <Menu.Item
                name="request"
                // onClick={this.handleAdminMenuItemClick}
                onClick={(_, val) => {
                  this.handleAdminMenuItemClick.bind(_, val);
                  localStorage.removeItem("draftId");
                }}
              >
                <Image
                  centered
                  src={`${process.env.PUBLIC_URL}/eco.png`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "32px",
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    borderTopRightRadius: 10,
                    borderTopLeftRadius: 10,
                    // marginBottom: "10px" ,
                  }}
                />
                กรอกข้อมูล
              </Menu.Item>
            </Link>

            {/* <Menu.Header>Products</Menu.Header>

              <Menu.Menu>
                <Menu.Item name="enterprise" onClick={this.handleItemClick} />
                <Menu.Item name="consumer" onClick={this.handleItemClick} />
              </Menu.Menu> */}

            <Menu.Item>
              {localStorage.getItem("name") ? (
                <React.Fragment>
                  <Icon size="small" name="user" />
                  {localStorage.getItem("name")}

                  <Menu.Menu>
                    <Link to={this.state.URLEdit}>
                      <Menu.Item onClick={this.handleEditClick}>
                        {/* <Icon name="edit" /> */}
                        <label> แก้ไขข้อมูล</label>
                      </Menu.Item>
                    </Link>
                    <Menu.Item
                      onClick={() => this.openModalCompanylogoWebsite()}
                    >
                      {/* <Icon name="image" /> */}

                      <label>อัพโหลดภาพเครื่องหมายการค้า</label>
                    </Menu.Item>
                    {this.state.can_update_password &&  <Menu.Item>
                      {/* <Icon name="key" /> */}

                      <label>เปลี่ยนรหัสผ่าน</label>
                    </Menu.Item>}
                   
                    <Link to="/">
                      <Menu.Item
                        onClick={() => {
                          localStorage.clear();
                        }}
                      >
                        <label
                          style={{ justifyContent: "center", color: "red" }}
                        >
                          ออกจากระบบ
                        </label>
                      </Menu.Item>
                    </Link>
                  </Menu.Menu>
                </React.Fragment>
              ) : (
                <Link to="/company/login">
                  <Button basic color="violet">
                    เข้าสู่ระบบ
                  </Button>
                </Link>
              )}
            </Menu.Item>
          </Sidebar>

          <Sidebar.Pusher
            style={{ maxHeight: `100vh`, minHeight: `50vh` }}
          ></Sidebar.Pusher>
        </Sidebar.Pushable>
       
        <Grid>
          <Grid.Row centered only="mobile">
            <Grid.Column>
              {/* <Menu pointing secondary>
                <Menu.Item name="editorials" onClick={this.handleItemClick}>
                  Editorials
                </Menu.Item>
                
              </Menu> */}
              <Segment>
                <Grid columns="equal">
                  <Grid.Row verticalAlign="middle">
                    <Grid.Column>
                      <Icon
                        onClick={() => this.setState({ isSidebarOpen: true })}
                        size="large"
                        name="bars"
                      />
                    </Grid.Column>
                    <Grid.Column>
                      {" "}
                      <Image size="tiny" src={`${process.env.PUBLIC_URL}/imagelogin/logobg.png`} />
                    </Grid.Column>
                    <Grid.Column />
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row only="tablet computer">
            <Grid.Column>
              <Menu icon="labeled" stackable>
                {/* <Menu.Item>
						<Image src={headerLogo} size='mini' />
					</Menu.Item> */}
                {/* <Responsive minWidth={Responsive.onlyMobile.minWidth}> */}
                <Menu.Item onClick={this.handleClickLogo}>
                  <Image
                    src={`${process.env.PUBLIC_URL}/imagelogin/logobg.png`}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "90px",
                      borderBottomLeftRadius: 15,
                      borderBottomRightRadius: 15,
                      borderTopRightRadius: 15,
                      borderTopLeftRadius: 15,
                    }}
                  />
                </Menu.Item>

                <Link to="/company/main">
                  <Menu.Item
                    name="request"
                    target="_self"
                    active={this.state.activeAdminMenuItem === "main"}
                    onClick={this.handleAdminMenuItemClick}
                  >
                    <Icon name="home" />
                    หน้าแรก
                  </Menu.Item>
                </Link>

                <Link to="/company/request-license">
                  <Menu.Item
                    name="request"
                    active={this.state.activeAdminMenuItem === "request"}
                    // onClick={this.handleAdminMenuItemClick}
                    onClick={(_, val) => {
                      this.handleAdminMenuItemClick.bind(_, val);
                      localStorage.removeItem("draftId");
                    }}
                  >
                    <Image
                      src={`${process.env.PUBLIC_URL}/eco.png`}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "32px",
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                        borderTopRightRadius: 10,
                        borderTopLeftRadius: 10,
                        // marginBottom: "10px" ,
                      }}
                    />
                    กรอกข้อมูล
                  </Menu.Item>
                </Link>
                {/*  Menu อัปโหลดเอกสาร */}

                {/* <Menu.Item
						name='payment'
						href='/company/request-payment'
						active={this.state.activeAdminMenuItem === 'payment'}
						onClick={this.handleAdminMenuItemClick}
						onClick={() => {
							this.handleAdminMenuItemClick.bind(this);

						}}
					>
						อัปโหลดเอกสารชำระเงิน
					</Menu.Item> */}
                {/* <Menu.Menu position='right'>
					<Dropdown item text='Categories'>
						<Dropdown.Menu>
							<Dropdown.Item>Electronics</Dropdown.Item>
							<Dropdown.Item>Automotive</Dropdown.Item>
							<Dropdown.Item>Home</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
					</Menu.Menu> */}

                <Menu.Menu position="right" style={{ marginTop: "20px" }}>
                  {localStorage.getItem("name") ? (
                    <React.Fragment>
                      <Dropdown
                        trigger={
                          <span>
                            <Icon name="user" />
                            {"" + localStorage.getItem("name")}
                          </span>
                        }
                        simple
                      >
                        <Dropdown.Menu>
                          <Dropdown.Header>
                            <Icon name="user" />
                            {localStorage.getItem("name")}
                          </Dropdown.Header>
                          <Divider />
                          <Dropdown
                            className="itemHeaderDropdown"
                            onClick={this.handleEditClick}
                            // icon
                            style={{
                              display: "block",
                            }}
                          >
                            <Link
                              to={this.state.URLEdit}
                              className="itemDropdown"
                            >
                              <Icon name="edit" />
                              <label> แก้ไขข้อมูล</label>
                            </Link>
                          </Dropdown>
                          <Dropdown.Item
                            style={{
                              display: "block",
                              width: "100%",
                              height: "100%",
                              padding: "0.78571429em 1.14285714em",
                              color: "black",
                            }}
                            onClick={() => this.openModalCompanylogoWebsite()}
                          >
                            <Icon name="image" />

                            <label>อัพโหลดภาพเครื่องหมายการค้า</label>
                          </Dropdown.Item>
                          {this.state.can_update_password &&  <Dropdown.Item
                            style={{
                              display: "block",
                              width: "100%",
                              height: "100%",
                              padding: "0.78571429em 1.14285714em",
                              color: "black",
                            }}
                            onClick={() => {
                              this.setState({ modalChangePassword: true });
                            }}
                          >
                            <Icon name="key" />

                            <label>เปลี่ยนรหัสผ่าน</label>
                          </Dropdown.Item>}
                         
                          <Divider />
                          <Link to="/">
                            <Dropdown.Item
                              style={{
                                display: "block",
                                width: "100%",
                                height: "100%",
                                padding: "0.78571429em 1.14285714em",
                                color: "black",
                              }}
                              onClick={() => {
                                localStorage.clear();
                              }}
                            >
                              <label style={{ justifyContent: "center" }}>
                                ออกจากระบบ
                              </label>
                            </Dropdown.Item>
                          </Link>
                        </Dropdown.Menu>
                      </Dropdown>
                    </React.Fragment>
                  ) : (
                    <Menu.Item>
                      <Link to="/company/login">
                        <Button basic color="violet">
                          เข้าสู่ระบบ
                        </Button>
                      </Link>
                    </Menu.Item>
                  )}
                </Menu.Menu>
              </Menu>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {/* เปลี่ยนรหัสผ่าน */}
        <Modal dimmer="blurring" open={this.state.modalChangePassword}>
          <Modal.Header>
            <Icon name="key" /> เปลี่ยนรหัสผ่าน
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              {this.state.messageError && (
                <center>
                  <div style={{ marginBottom: 20, width: "62%" }}>
                    <Message negative>
                      <Message.Header>{this.state.messageText}</Message.Header>
                    </Message>
                  </div>
                </center>
              )}
              <Form loading={this.state.loading}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column computer={5}>
                      <h5
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                          marginTop: 10,
                        }}
                      >
                        รหัสผ่านเดิม
                      </h5>
                    </Grid.Column>
                    <Grid.Column computer={8}>
                      <Form.Field>
                        <Input
                          type="password"
                          fluid
                          value={this.state.currentPassword}
                          name="currentPassword"
                          onChange={(e, { name, value }) => {
                            this.handleOnChange(name, value);
                          }}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column computer={5}>
                      <h5
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          marginTop: 10,
                        }}
                      >
                        รหัสผ่านใหม่
                      </h5>
                    </Grid.Column>
                    <Grid.Column computer={8}>
                      <Form.Field>
                        <Input
                          type="password"
                          fluid
                          value={this.state.newPassword}
                          name="newPassword"
                          onChange={(e, { name, value }) => {
                            this.handleOnChange(name, value);
                          }}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column computer={5}>
                      <h5
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignSelf: "center",
                          marginTop: 10,
                        }}
                      >
                        ยืนยันรหัสผ่านใหม่
                      </h5>
                    </Grid.Column>
                    <Grid.Column computer={8}>
                      <Form.Field>
                        <Input
                          type="password"
                          fluid
                          value={this.state.confirmPassword}
                          name="confirmPassword"
                          onChange={(e, { name, value }) => {
                            this.handleOnChange(name, value);
                          }}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button color="red" onClick={this.closeModal}>
              ยกเลิก
            </Button>
            <Button positive content="ยืนยัน" onClick={this.checkPassword} />
          </Modal.Actions>
        </Modal>
        {/* อัพโหลดรูป logo */}
        <Modal open={this.state.modalUploadlogo}>
          <Modal.Header>
            <Icon name="image" /> อัพโหลดภาพเครื่องหมายการค้า
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form loading={this.state.loading}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column computer={5}>
                      <h5
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                          marginTop: 10,
                        }}
                      >
                        เว็บไซต์
                      </h5>
                    </Grid.Column>
                    <Grid.Column computer={8}>
                      <Form.Field error={this.state.company_website_error}>
                        <Input
                          type="text"
                          fluid
                          value={this.state.company_website}
                          name="company_website"
                          onChange={(e, { name, value }) => {
                            this.handleOnChange(name, value);
                          }}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column computer={5}>
                      <h5
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          marginTop: 10,
                        }}
                      >
                        อัพโหลดภาพเครื่องหมายการค้า(logo)
                      </h5>
                    </Grid.Column>
                    <Grid.Column computer={8}>
                      <Form.Field>
                        <Segment>
                          <Label
                            attached="top"
                            color={
                              this.state.file_company_logo_error
                                ? "red"
                                : "violet"
                            }
                            as="label"
                            htmlFor="file_company_logo"
                            size="large"
                          >
                            <Icon name="upload" />
                            เลือกไฟล์
                            <input
                              hidden
                              type="file"
                              id="file_company_logo"
                              name="file_company_logo"
                              accept=".jpeg, .png"
                              onChange={this.handleUploadFileChange.bind(this)}
                            />
                          </Label>
                          <p>
                            {this.state.file_company_logo.name}
                            &nbsp; &nbsp;
                            {this.state.file_company_logo.name ? (
                              <Icon
                                id="file_company_logo_reset"
                                color="red"
                                size="large"
                                name="times circle outline"
                                link
                                onClick={this.handleUploadFileReset.bind(this)}
                              />
                            ) : null}
                          </p>
                        </Segment>
                        <p
                          style={{
                            color: "#db2828",
                            fontWeight: "bold",
                            paddingTop: "1rem",
                          }}
                        >
                          ชนิดไฟล์ที่ใช้อัปโหลด : .jpeg, .png ขนาดไฟล์ไม่เกิน 10
                          M
                        </p>
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="red"
              onClick={() =>
                this.setState({
                  modalUploadlogo: false,
                  file_company_logo_error: false,
                  company_website_error: false,
                })
              }
            >
              ยกเลิก
            </Button>
            <Button
              loading={this.state.confirmButtonLogoWebsite}
              disabled={this.state.confirmButtonLogoWebsite}
              positive
              onClick={() => this.submitCompanyLogoWebsite()}
              content="ยืนยัน"
            />
          </Modal.Actions>
        </Modal>{" "}
      </React.Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    message: "This is message from mapStateToProps",
  };
};

export default withRouter(connect(mapStateToProps)(App));
