import React from "react";
import { Container, Icon } from "semantic-ui-react";
import styled from "styled-components";
import SuggestionIcon from "../assets/icon/suggestion.png";
import { useHistory } from "react-router-dom";

const StyledContainer = styled.div`
	padding: 30px;
	display: flex;
	flex-direction: column;
`;

const HeaderPage = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 20px;
`;

const BoxListMenuSetting = styled.div`
	display: flex;
	flex-direction: row;
	padding: 20px;
`;

export const Setting = () => {
	return (
		<Container>
			<StyledContainer>
				<HeaderPage>
					<Icon
						name="setting"
						style={{ marginRight: "10px" }}
					/>
					รายการเมนูตั้งค่า
				</HeaderPage>
				<BoxListMenuSetting>
					<CardMenuContent path={"/setting/suggestion"} />
				</BoxListMenuSetting>
			</StyledContainer>
		</Container>
	);
};

const CardContainer = styled.div`
	padding: 20px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	border-radius: 10px;
	cursor: pointer;
`;

const BoxDisplayFlexRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;
const BoxDisplayFlexColumn = styled.div`
	display: flex;
	flex-direction: column;
	p {
		font-size: 16px;
		font-weight: 500;
		color: #333333;
		padding: 0;
		margin: 0;
	}
	span {
		font-size: 14px;
		font-weight: 400;
		color: gray;
	}
`;

const CardMenuContent = ({ path }) => {
	let history = useHistory();
	return (
		<CardContainer onClick={() => history.push(path)}>
			<BoxDisplayFlexRow>
				<div style={{ width: "50px", height: "50px", objectFit: "contain", marginRight: "10px" }}>
					<img
						style={{ width: "100%", height: "100%" }}
						src={SuggestionIcon}
					/>
				</div>
				<BoxDisplayFlexColumn>
					<p>รายการคำแนะนำชื่อทางการค้า</p>
					<span>เพิ่มรายการแนะนำคำในการกรอกบันทึกข้อมูล</span>
				</BoxDisplayFlexColumn>
			</BoxDisplayFlexRow>
		</CardContainer>
	);
};
