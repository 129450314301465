import React, { useState } from "react";
import { Icon, Grid, GridColumn, Input, Divider } from "semantic-ui-react";
import {
	BoxSelectOption,
	BoxListSelected,
	ChipContainer,
	Chip,
	BoxSelectContent,
	ButtonStyled,
	ImageContainer,
	StyledImage,
} from "./StyledComponent";
import EmptyBox from "../../../assets/icon/box.png";

const BoxMultipleSelect = ({ data = [], handleSubmit }) => {
	const [selected_data, setSelectedData] = useState([]);
	const [search_text, setsearchText] = useState("");

	const handleOnChange = (e) => {
		setsearchText(e.target.value);
	};

	const onChangeSelect = (name) => {
		if (selected_data.includes(name)) {
			setSelectedData(selected_data.filter((item) => item !== name));
			return;
		}
		setSelectedData([...selected_data, name]);
	};

	const handleSubmitCreateListSuggestion = (val) => {
		handleSubmit(val);
		setSelectedData([]);
	};

	const filteredData = data.filter((item) => item.name.toLowerCase().includes(search_text.toLowerCase()));

	return (
		<BoxSelectOption style={{ height: "80vh" }}>
			<div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
				<span>รายการชื่อแบรนด์</span>
			</div>
			<BoxListSelected style={{ height: "30%", padding: "10px 15px" }}>
				<ChipContainer>
					{selected_data?.length > 0 ? (
						selected_data.map((item, i) => (
							<Chip key={i}>
								{item}
								<Icon
									style={{ marginLeft: "5px" }}
									link
									name="x icon"
									onClick={() => onChangeSelect(item)}

								/>
							</Chip>
						))
					) : (
						<div
							style={{
								width: "100%",
								height: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								color: "#b9b9b9",
								userSelect: "none",
							}}
						>
							กรุณาเลือกแบรนด์
						</div>
					)}
				</ChipContainer>
			</BoxListSelected>
			<div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", marginTop: "10px",marginBottom:"10px" }}>
				<ButtonStyled
					disabled={selected_data?.length === 0}
					color={selected_data?.length > 0 && "success"}
					onClick={() => handleSubmitCreateListSuggestion(selected_data)}
				>
					บันทึก
				</ButtonStyled>
				<ButtonStyled onClick={() => setSelectedData([])}>ยกเลิก</ButtonStyled>
			</div>
			<Divider horizontal>เพิ่มคำแนะนำ</Divider>
			<div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", marginTop: "10px" }}>
				<Input
					icon={{ name: "search", circular: true, link: true }}
					placeholder="ค้นหาชื่อแบรนด์"
					style={{ width: "200px" }}
					size="small"
					onChange={handleOnChange}
				/>
			</div>
			<BoxListSelected>
				<div style={{ width: "100%" }}>
					{filteredData?.length > 0 ? (
						<Grid columns={1}>
							{filteredData?.map((item) => (
								<GridColumn style={{ padding: "5px" }}>
									<BoxSelectContent
										active={selected_data?.includes(item.name)}
										onClick={() => onChangeSelect(item.name)}
									>
										{item.name}
										{selected_data?.includes(item.name) && <Icon name="checkmark" />}
									</BoxSelectContent>
								</GridColumn>
							))}
						</Grid>
					) : (
						<div
							style={{
								width: "100%",
								height: "100%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								color: "#b9b9b9",
								userSelect: "none",
							}}
						>
							<ImageContainer>
								<StyledImage src={EmptyBox} />
							</ImageContainer>
							<span>ไม่พบข้อมูล</span>
						</div>
					)}
				</div>
			</BoxListSelected>
		</BoxSelectOption>
	);
};

export default BoxMultipleSelect;
