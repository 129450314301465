import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import Sweet from 'sweetalert2';
import { Apiurl2 } from '../../router';
import ApprovalHeader from '../ApprovalHeader';
import { Segment, Grid, Input, Button } from 'semantic-ui-react';

class ApprovalLogin extends Component {
	state = {
		username: '',
		password: '',
		wrongaccount: ''
	};
	handleChange = (e) => {
		this.setState({
			[e.target.id]: e.target.value
		});
	};
	handleSubmit = (e) => {
		e.preventDefault();
	};
	componentWillMount = () => {
		if (typeof this.props.location.state !== 'undefined') {
		}
		else if (`${localStorage.getItem('type_user_approve')}` === '2') {
			this.props.history.push('/oie');
		}
	};
	login = () => {
		const data = { username: this.state.username, password: this.state.password };
		Axios.post(Apiurl2 + '/approve/login', data)
			.then((res) => {
				Sweet.fire({
					position: 'center',
					type: 'success',
					title: 'เข้าสู่ระบบสำเร็จ',
					showConfirmButton: false,
					timer: 1500
				}).then(() => {
					localStorage.setItem('token_approve', res.data.token);
					localStorage.setItem('type_user_approve', res.data.Role);
					localStorage.setItem('account', data.username);
					if (res.data.Role === 3 || res.data.Role === 2) {
						this.props.history.push('/oie');
					}
					else {
						// this.props.history.push("/approval/detail")
					}
				});
			})
			.catch((err) => {
				this.setState({ wrongaccount: '* Username หรือ Password ผิด' });
			});
	};
	render() {
		return (
			<div>
				<ApprovalHeader />
				<div onKeyPress={(event) => event.key === 'Enter' && this.login()}>
					<Grid columns='equal'>
						<Grid.Column />
						<Grid.Column computer={4} tablet={10} mobile={16}>
							<Segment style={{ backgroundColor: '#E9E9E9', borderRadius: '5px', marginTop: '100px' }}>
								<div>
									<div style={{ textAlign: 'center' }}>
										<img src={`${process.env.PUBLIC_URL}/imagelogin/logo2.png`} alt='logo' style={{ height: '200px' }} />
									</div>
									<div>
										<h4 htmlFor='username'>
											Username <br />
										</h4>
										<Input
											style={{ marginBottom: 10, width: '100%' }}
											type='username'
											id='username'
											placeholder='Username'
											onBlur={this.handleChange}
										/>
									</div>
									<div>
										<h4 htmlFor='password'>
											Password <br />
										</h4>
										<Input
											style={{ width: '100%' }}
											type='password'
											id='password'
											placeholder='Password'
											onBlur={this.handleChange}
										/>
									</div>
									<div style={{ marginTop: '10px', color: 'red' }}>
										<h4>{this.state.wrongaccount}</h4>
									</div>
									<Button
										style={{ marginTop: '30px', minWidth: '100%', backgroundColor: '#030C37' }}
										onClick={() => this.login()}
									>
										<h4 style={{ color: 'white' }}>Login</h4>
									</Button>
								</div>
							</Segment>
						</Grid.Column>
						<Grid.Column />
					</Grid>
				</div>
			</div>
		);
	}
}

export default withRouter(ApprovalLogin);
