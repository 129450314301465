import React, { Component } from "react";
import { connect } from "react-redux";
import _ from 'lodash'
import { Button, Header, Icon, Image, Modal } from 'semantic-ui-react'

class requestTestModal extends Component {
    constructor(props) {
        super(props);
        this.state = {isTestModalOpen : false};
    }

    componentWillMount = async () => {
        await this.setState(this.props.requestRedux)
    }

    componentWillReceiveProps(nextProps) {
        // console.log('next props',)
        this.setState(nextProps.requestRedux)
    }

    render() {
        // console.log('props from modal',this.props)
        return (
            <Modal 
            open={this.state.isTestModalOpen}
            onClose={() =>{
                this.setState({isTestModalOpen : false})
            }}>
                <Modal.Header>ชื่อทางการค้าที่ {this.props.brand_index} : {this.props.brandName}</Modal.Header>
                <Modal.Content image scrolling>
                    <Image size='medium' src={`${process.env.PUBLIC_URL}/images/wireframe/image.png`} wrapped />

                    <Modal.Description>
                        <Header>Modal Header</Header>
                        <p>
                            This is an example of expanded content that will cause the modal's
                            dimmer to scroll
        </p>

                        {_.times(8, (i) => (
                            <Image
                                key={i}
                                src={`${process.env.PUBLIC_URL}/images/wireframe/paragraph.png`}
                                style={{ paddingBottom: 5 }}
                            />
                        ))}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary>
                        Proceed <Icon name='chevron right' />
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }


}

const mapStateToProps = function (state) {
    return {
        requestRedux: state.requestRedux
    };
};
export default connect(mapStateToProps)(requestTestModal)